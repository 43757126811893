import _ from 'lodash';
import {
    Alert,
    Box,
    Icon,
    Link,
    Select,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3/polaris';
import React from 'react';

export const getColumnsToDisplay = (learnerActivity, editMode) => {
    return _.concat(
        { id: 'email', visible: true },
        { id: 'lastName', visible: true },
        _.map(learnerActivity.delivery_sessions, (deliverySession, index) => {
            return {
                id: `session${index}`,
                visible: true,
            };
        }),
        { id: 'attendanceStatus', visible: !editMode },
        { id: 'modificationStatus', visible: editMode }
    );
};

export const getPaginationLabels = stringBundle => {
    return {
        nextPageLabel: stringBundle.listControlNextPage(),
        pageLabel: pageNumber => stringBundle.listControlGoToPage(pageNumber),
        previousPageLabel: stringBundle.listControlNextPage(),
    };
};

export const getColumnDefinitions = (
    learnerActivity,
    attendanceState,
    attendanceStateDispatcher,
    stringBundle
) => {
    const statusToLabel = {
        ATTENDED: stringBundle.statusAttended(),
        NOT_MARKED: stringBundle.statusNotMarked(),
        NO_SHOW: stringBundle.statusNoShow(),
        PARTIALLY_ATTENDED: stringBundle.statusPartiallyAttended(),
    };
    const attendanceStatusOptions = [
        { label: statusToLabel.ATTENDED, value: 'ATTENDED' },
        { label: statusToLabel.PARTIALLY_ATTENDED, value: 'PARTIALLY_ATTENDED' },
        { label: statusToLabel.NO_SHOW, value: 'NO_SHOW' },
    ];

    const getSelectedStatusOption = (attendanceRecord, deliverySessionId) => {
        const status = _.find(
            attendanceRecord.deliverySessionAttendanceRecords,
            deliverySessionAttendance =>
                _.eq(deliverySessionAttendance.deliverySessionId, deliverySessionId),
            0
        )?.attendanceStatus;
        switch (status) {
            case 'ATTENDED':
                return { label: stringBundle.statusAttended(), value: statusToLabel.ATTENDED };
            case 'PARTIALLY_ATTENDED':
                return {
                    label: stringBundle.statusPartiallyAttended(),
                    value: statusToLabel.PARTIALLY_ATTENDED,
                };
            case 'NO_SHOW':
                return { label: stringBundle.statusNoShow(), value: statusToLabel.NO_SHOW };
            default:
                return { label: stringBundle.statusNotMarked(), value: statusToLabel.NOT_MARKED };
        }
    };

    const getStatusLabel = attendanceStatus =>
        statusToLabel[attendanceStatus] ?? statusToLabel.NOT_MARKED;

    return _.concat(
        {
            id: 'email',
            header: stringBundle.columnEmail(),
            cell: attendanceRecord => attendanceRecord.email,
            sortingField: 'email',
            isRowHeader: true,
        },
        {
            id: 'lastName',
            header: stringBundle.columnLastName(),
            cell: attendanceRecord => attendanceRecord.lastName,
            sortingField: 'lastName',
            isRowHeader: true,
        },
        _.map(learnerActivity.delivery_sessions, (deliverySession, index) => {
            return {
                id: `session${index}`,
                header: stringBundle.columnNSession(index + 1),
                cell: attendanceRecord => {
                    if (attendanceState.editMode) {
                        return (
                            <Select
                                selectedOption={getSelectedStatusOption(
                                    attendanceRecord,
                                    deliverySession.id
                                )}
                                onChange={event =>
                                    attendanceStateDispatcher.onSingleSessionAttendanceChange(
                                        event,
                                        attendanceRecord.learner.id,
                                        deliverySession.id,
                                        stringBundle
                                    )
                                }
                                options={attendanceStatusOptions}
                                placeholder={statusToLabel.NOT_MARKED}
                                disabled={attendanceState.attendanceUpdateInProgress}
                                expandToViewport
                            />
                        );
                    } else {
                        return getSelectedStatusOption(attendanceRecord, deliverySession.id).label;
                    }
                },
                isRowHeader: true,
                sortingComparator: (item1, item2) => {
                    const deliverySession1 = _.find(
                        item1.deliverySessionAttendanceRecords,
                        deliverySessionAttendance =>
                            _.eq(deliverySession.id, deliverySessionAttendance.deliverySessionId),
                        0
                    );
                    const deliverySession2 = _.find(
                        item2.deliverySessionAttendanceRecords,
                        deliverySessionAttendance =>
                            _.eq(deliverySession.id, deliverySessionAttendance.deliverySessionId),
                        0
                    );
                    return deliverySession1.attendanceStatus.localeCompare(
                        deliverySession2.attendanceStatus
                    );
                },
            };
        }),
        {
            id: 'attendanceStatus',
            header: stringBundle.columnClassAttendance(),
            cell: attendanceRecord =>
                getStatusLabel(attendanceRecord.learningActivityAttendanceStatus),
            isRowHeader: true,
            sortingComparator: (item1, item2) =>
                item1.learningActivityAttendanceStatus.localeCompare(
                    item2.learningActivityAttendanceStatus
                ),
        },
        {
            id: 'modificationStatus',
            header: stringBundle.columnModified(),
            cell: attendanceRecord => {
                if (
                    attendanceState.learnersWithSessionAttendanceChanges.has(
                        attendanceRecord.learner.id
                    )
                ) {
                    return (
                        <SpaceBetween direction="horizontal" size="s">
                            <Icon name="status-positive" variant="success" />
                            <Link
                                onFollow={() =>
                                    attendanceStateDispatcher.onUndoAttendanceRecordChange(
                                        attendanceRecord.learner.id
                                    )
                                }
                            >
                                {stringBundle.resetText()}
                            </Link>
                        </SpaceBetween>
                    );
                }
            },
            isRowHeader: true,
            sortingComparator: (item1, item2) => {
                const item1Value = attendanceState.learnersWithSessionAttendanceChanges.has(
                    item1.learner.id
                )
                    ? 1
                    : 0;
                const item2Value = attendanceState.learnersWithSessionAttendanceChanges.has(
                    item2.learner.id
                )
                    ? 1
                    : 0;
                return item2Value - item1Value;
            },
        }
    );
};

export function EmptyState({ title, subtitle, action }) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

export function getErrorSection(error, stringBundle) {
    if (error?.graphQLErrors?.length > 0) {
        let errorBody;
        if (
            _.isString(error.graphQLErrors[0].message) &&
            error.graphQLErrors[0].message.toLowerCase().indexOf('unauthorized') >= 0
        ) {
            errorBody = stringBundle.errorTextNotAuthorized();
        } else {
            errorBody = stringBundle.errorTextDefault();
        }
        return (
            <Alert
                statusIconAriaLabel={stringBundle.errorHeaderDefault()}
                header={stringBundle.errorHeaderDefault()}
            >
                {errorBody}
            </Alert>
        );
    }
}
