import { useEffect, useContext } from 'react';
import { getClassroomWithInstructors } from 'graphql/custom';
import { ClassroomContext } from 'data/ClassroomContext';
import { useAcceptInvitations } from 'utils/useAcceptInvitations';
import { executeRequest } from 'modules/api';
import { useProvider } from './ProviderContext';
import { useQuery } from 'react-query';
import { isEmpty } from 'utils/helpers';
import { useErrorHandler } from 'react-error-boundary';
import { useFlags } from 'utils/flags';

const DELAYS = [500, 1000, 1500, 2000];

const useQueryOptions = {
    refetchOnWindowFocus: false,
    staleTime: 5000,
    retryDelay: retryNum => DELAYS[retryNum],
};

const fetchClassroom = async ({
    classroomId,
    provider,
    withStudentRoster,
    withSubProviderCapacity,
}) => {
    const {
        getClassroom: payload,
        listClassroomRelationships,
        studentRoster,
    } = await executeRequest({
        operation: getClassroomWithInstructors,
        params: {
            classroomId,
            providerArn: provider.arn,
            withStudentRoster,
            withSubProviderCapacity,
        },
        flag: true,
    });
    payload.classroom.trainingProviderName = provider.name;
    payload.classroom.trainingProviderType = provider.type;
    payload.classroom.subProviderData = provider.subProviderData;
    payload.classroom.requiresSubProvider = provider.requiresSubProvider;
    if (listClassroomRelationships?.classroomUsers) {
        payload.classroom.instructors = listClassroomRelationships.classroomUsers.map(
            classroomUser => classroomUser.email
        );
    }
    if (studentRoster?.classroomUsers) {
        payload.classroom.studentRoster = studentRoster.classroomUsers.map(item => item);
    }
    return payload;
};

export const useClassroomData = (classroomId, { withConsistencyRetry = false } = {}) => {
    const flags = useFlags();

    const setError = useErrorHandler();
    const { classData, loadInitialState, ...rest } = useContext(ClassroomContext);
    const provider = useProvider();
    const acceptedInvitations = useAcceptInvitations();

    const { data, error, refetch } = useQuery(
        [classroomId, provider],
        () => {
            if (classroomId && provider?.arn) {
                return fetchClassroom({
                    classroomId,
                    provider,
                    withStudentRoster: flags.studentRoster,
                    withSubProviderCapacity: flags.multiGilmoreId,
                });
            }
        },
        {
            ...useQueryOptions,
            retry: (retryNum, err) => {
                let toRetry = false;
                if (
                    err.statusCode === 403 &&
                    acceptedInvitations &&
                    acceptedInvitations.length > 0
                ) {
                    toRetry = true;
                } else if (err.statusCode >= 500) {
                    toRetry = true;
                }
                return withConsistencyRetry && toRetry && retryNum < DELAYS.length;
            },
        }
    );

    useEffect(() => {
        if (error) {
            setError(error);
        } else if (!isEmpty(data)) {
            loadInitialState(data);
        }
    }, [data, loadInitialState, error, setError]);

    const isJamOnlyClassroom = !classData?.content?.length;
    return { classData, isJamOnlyClassroom, refetch, ...rest };
};
