import React from 'react';
import { useQuery } from 'react-query';
import _get from 'lodash/get';
import { getCurrentUser } from 'utils/helpers';
import { ProviderCtx } from 'data/ProviderContext';
import { getUserRoles } from 'modules';

const TC_RELATIONSHIP_TYPE = 'training_coordinator';

const getUserDetails = async () => {
    const { email, hat_id, sub, ...rest } = await getCurrentUser();
    return {
        email: email || `${rest['cognito:username']}@amazon.com`,
        userKey: hat_id || sub,
        email_verified: rest['email_verified'],
        identities: rest['identities'],
        public_provider_name: rest['public_provider_name'] || 'COGNITO',
    };
};

const useQueryOptions = { refetchOnWindowFocus: false };

export const UserInfoCtx = React.createContext();

export const UserInfoProvider = ({ children }) => {
    const providerArn = _get(React.useContext(ProviderCtx), 'provider.arn');
    const { data: userDetails, isLoading: loadingEmail } = useQuery(
        'userDetails',
        getUserDetails,
        useQueryOptions
    );
    const { data: roles, isLoading: loadingRoles } = useQuery(
        ['userRoles', providerArn],
        () => (providerArn ? getUserRoles(providerArn) : null),
        useQueryOptions
    );

    const userIsTrainingCoordinator = (roles || []).includes(TC_RELATIONSHIP_TYPE);

    const userInfo = {
        ...userDetails,
        roles,
        userIsTrainingCoordinator,
        isLoading: loadingEmail || loadingRoles,
    };

    return <UserInfoCtx.Provider value={userInfo}>{children}</UserInfoCtx.Provider>;
};

export const useUserInfo = () => React.useContext(UserInfoCtx);
