import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { paths } from '../../utils';
import messages from '../cancelClass/CancelClass.messages';
import { ProviderType } from 'containers/providerPages/ProvidersPage';

export const CancelClassConfirmationDialog = ({
    isVisible,
    setIsVisible,
    onConfirm,
    trainingProviderType,
}) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const dismiss = () => {
        setErrorMessage('');
        setIsVisible(false);
    };

    const confirm = async () => {
        setIsLoading(true);
        try {
            await onConfirm();
            history.push(paths.classListPage);
        } catch (e) {
            setErrorMessage(formatMessage(messages.cancelClassFailedError));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Modal
                visible={isVisible}
                onDismiss={dismiss}
                closeLabel={formatMessage(messages.backButtonLabel)}
                header={formatMessage(messages.modalTitle)}
                footer={
                    <div className="cancel-class-modal__footer">
                        <Box color="text-status-error" data-testid="cancel-class-error">
                            {errorMessage}
                        </Box>
                        <SpaceBetween direction="horizontal" size="s">
                            <Button
                                onClick={confirm}
                                variant="primary"
                                loading={isLoading}
                                data-testid="cancel-class-confirm-button"
                            >
                                {formatMessage(messages.confirmButtonLabel)}
                            </Button>
                            <Button
                                onClick={dismiss}
                                variant="normal"
                                disabled={isLoading}
                                data-testid="cancel-class-back-button"
                            >
                                <span data-testid="cancel-class-modal__back-btn">
                                    {formatMessage(messages.backButtonLabel)}
                                </span>
                            </Button>
                        </SpaceBetween>
                    </div>
                }
                data-testid="cancel-class-modal"
            >
                {formatMessage(
                    trainingProviderType === ProviderType.Direct
                        ? messages.dialogMessage
                        : messages.dialogMessageForATP
                )}
            </Modal>
        </>
    );
};

export default CancelClassConfirmationDialog;
