import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@amzn/awsui-components-react-v3';

import { paths } from 'utils';
import { CardContainer } from 'components';
import messages from './LabCard.messages';

const LabCard = props => {
    const { index } = props;
    const { formatMessage } = useIntl();
    const { classroomId } = useParams();
    const history = useHistory();

    const btnOnClick = () => history.push(paths.labPage(classroomId, index + 1));

    return (
        <CardContainer {...props}>
            <Button
                onClick={btnOnClick}
                ariaLabel={`${formatMessage(messages.manageLabsBtnLabel)} - ${props?.title}`}
            >
                {formatMessage(messages.manageLabsBtnLabel)}
            </Button>
        </CardContainer>
    );
};

export default LabCard;
