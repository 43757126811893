import ReactDOM from 'react-dom';
import React from 'react';
import '@amzn/awsui-components-react/index.css';
import { BrowserRouter } from 'react-router-dom';
import App from 'containers/app/App';
import { ComponentDidCatch, ScrollToTop, Locale } from './components';
import { configureAmplify } from './modules';
import { ClassroomProvider } from './data/ClassroomContext';
import { FlagContextProvider } from 'utils';
import { ApolloProvider } from '@apollo/client';
import { superGraphClient } from './modules/supergraph/client';

configureAmplify();

const Root = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <FlagContextProvider>
                <ApolloProvider client={superGraphClient}>
                    <Locale>
                        <ComponentDidCatch>
                            <ClassroomProvider>
                                <App />
                            </ClassroomProvider>
                        </ComponentDidCatch>
                    </Locale>
                </ApolloProvider>
            </FlagContextProvider>
        </BrowserRouter>
    );
};

ReactDOM.render(<Root />, document.getElementById('root'));
