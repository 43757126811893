import * as JAM_STATUSES from 'utils/jam-status-definitions';
import messages from './JamStatusWidget.messages';

const {
    jamStatusPending,
    jamStatusUpdating,
    jamStatusNotStarted,
    jamStatusOngoing,
    jamStatusEnded,
    jamStatusCancelled,
    jamStatusError,
} = messages;

export const getStatusTranslationMessageBase = formatFn => status => {
    switch (status) {
        case JAM_STATUSES.JAM_EVENT_PENDING:
        case JAM_STATUSES.JAM_EVENT_PENDING_APPROVAL:
            return formatFn(jamStatusPending);
        case JAM_STATUSES.JAM_EVENT_UPDATING:
            return formatFn(jamStatusUpdating);
        case JAM_STATUSES.JAM_EVENT_NOT_STARTED:
            return formatFn(jamStatusNotStarted);
        case JAM_STATUSES.JAM_EVENT_ONGOING:
            return formatFn(jamStatusOngoing);
        case JAM_STATUSES.JAM_EVENT_ENDED:
            return formatFn(jamStatusEnded);
        case JAM_STATUSES.JAM_EVENT_CANCELLED:
            return formatFn(jamStatusCancelled);
        case JAM_STATUSES.JAM_EVENT_ERROR:
            return formatFn(jamStatusError);
        default:
            return '-';
    }
};
