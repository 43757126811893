import React, { useEffect, useState } from 'react';
import { Redirect, Switch, Route, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import {
    ClassPage,
    ClassDetailPage,
    ClassEditPage,
    ClassCreatePage,
    ErrorPage,
    LabPage,
    ProvidersPage,
    ClassListPage,
    ClassListPageV2,
    AttendancePage,
    ResourceConsolidationPage,
} from 'containers';
import { Authenticator, Header, Footer, ErrorFallback, Loader } from 'components';
import { ProviderCtxProvider } from 'data/ProviderContext';
import { paths, parameters, redirect } from 'utils/paths';
import { UserInfoProvider } from 'utils/userInfo';
import { useFlags } from 'utils/flags';
import { checkResourceConsolidation } from 'utils/resetResources';
import { QueryClientProviderWrapper } from 'utils/QueryClientProvider';
import './App.css';
import { GilmoreHealthProvider } from 'data/GilmoreHealthContext';
import UserKeyDisplay from 'components/userKeyDisplay/UserKeyDisplay';

const { classroomId } = parameters;

const App = ({ globals = window }) => {
    const features = useFlags();
    // eslint-disable-next-line no-unused-vars
    const { search } = useLocation();
    const [consolidated, setConsolidated] = useState(false);
    const [redirecting, setRedirecting] = useState(false);
    const [consolidationChecked, setConsolidationChecked] = useState(false);

    useEffect(() => {
        async function checkConsolidation() {
            const result = await checkResourceConsolidation(features.requiresReset);
            setConsolidated(result.hasResourcesConsolidated);
            setRedirecting(result.isRedirecting);
            setConsolidationChecked(true);
        }
        checkConsolidation();
    }, [consolidated, features.requiresReset]);

    return (
        <div className="awsui">
            <Authenticator globals={globals}>
                <QueryClientProviderWrapper>
                    <ProviderCtxProvider globals={globals}>
                        <UserInfoProvider>
                            <GilmoreHealthProvider>
                                <Header />
                                <main className="app-body">
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        {features.requiresReset &&
                                        (!consolidationChecked || redirecting) ? (
                                            <Loader isLoading={true}></Loader>
                                        ) : (
                                            <Switch>
                                                <Route
                                                    path="/callback"
                                                    exact
                                                    render={() => (
                                                        <Redirect
                                                            to={redirect(globals, features)}
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    path="/"
                                                    exact
                                                    render={() => (
                                                        <Redirect
                                                            to={redirect(globals, features)}
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    strict
                                                    path="(.*//+.*)"
                                                    render={({ location }) => (
                                                        <Redirect
                                                            to={location.pathname.replace(
                                                                /\/\/+/g,
                                                                '/'
                                                            )}
                                                        />
                                                    )}
                                                />
                                                {features.resourceConsolidation &&
                                                    !consolidated && (
                                                        <Route
                                                            path="*"
                                                            component={ResourceConsolidationPage}
                                                        />
                                                    )}
                                                <Route
                                                    exact
                                                    path={paths.providersPage}
                                                    component={ProvidersPage}
                                                />
                                                <Route
                                                    exact
                                                    path={paths.classListPage}
                                                    component={getClasslistingComponent(
                                                        search,
                                                        features
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path={paths.classPage('new')}
                                                    component={ClassCreatePage}
                                                />
                                                <Route
                                                    exact
                                                    path={paths.classPage(classroomId)}
                                                    component={ClassPage}
                                                />
                                                <Route
                                                    exact
                                                    path={paths.classDetailPage(
                                                        parameters.classroomId
                                                    )}
                                                    component={ClassDetailPage}
                                                />
                                                <Route
                                                    exact
                                                    path={paths.classEditPage(
                                                        parameters.classroomId
                                                    )}
                                                    component={ClassEditPage}
                                                />

                                                {features.attendanceMarking && (
                                                    <Route
                                                        exact
                                                        path={paths.classAttendance(
                                                            parameters.classroomId
                                                        )}
                                                        component={AttendancePage}
                                                    />
                                                )}

                                                <Route
                                                    path={paths.labPage(
                                                        parameters.classroomId,
                                                        parameters.labNumber
                                                    )}
                                                    component={LabPage}
                                                />
                                                <Route component={ErrorPage} />
                                            </Switch>
                                        )}
                                    </ErrorBoundary>
                                </main>
                            </GilmoreHealthProvider>
                            <UserKeyDisplay />
                            <Footer />
                        </UserInfoProvider>
                    </ProviderCtxProvider>
                </QueryClientProviderWrapper>
            </Authenticator>
        </div>
    );
};

/**
 * Returns ReactJS component for rendering classlisting.
 * When class listing phase V2 feature is enabled, allows overriding the component version
 * via query parameter (i.e. ?mode=v2).
 * CLASSLISTING_DEFAULT_VERSION determines what component is used by default when no override via query parameter
 * is present.
 */
// eslint-disable-next-line no-unused-vars
const getClasslistingComponent = (searchParams, features) => {
    if (features.classListingV2) {
        const urlParams = new URLSearchParams(searchParams);
        const componentVersion =
            urlParams.get(CLASSLISTING_VERSION_QUERY_PARAM_NAME) ?? CLASSLISTING_DEFAULT_VERSION;
        return componentVersion === 'v2' ? ClassListPageV2 : ClassListPage;
    } else {
        return ClassListPage;
    }
};

const CLASSLISTING_VERSION_QUERY_PARAM_NAME = 'mode';
const CLASSLISTING_DEFAULT_VERSION = 'v2';

export default App;
