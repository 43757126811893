import React from 'react';
import { useHistory } from 'react-router-dom';
import { BreadcrumbGroup } from '@amzn/awsui-components-react-v3';
import { messages } from 'index.messages';
import { useIntl } from 'react-intl';

const Breadcrumbs = ({ items }) => {
    const { formatMessage } = useIntl();
    const history = useHistory();

    const onBreadcrumbClick = e => {
        e.preventDefault();
        history.push(e.detail.href);
    };

    return (
        <BreadcrumbGroup
            items={items}
            onClick={onBreadcrumbClick}
            ariaLabel={formatMessage(messages.breadcrumbsLabel)}
        />
    );
};

export default Breadcrumbs;
