import React, { useState, useEffect } from 'react';
import {
    FormField,
    Input,
    Select,
    Form,
    Header,
    Container,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { useIntl } from 'react-intl';
import _get from 'lodash/get';
import { union } from 'lodash';
import {
    getAllCourseVersions,
    getCourseVersionDetails,
    listAuthorizedCourses,
    listMajorVersions,
} from 'modules';
import { messages } from '../ClassForm.messages';
import { usePrevious, handleFormValueChange } from '../ClassForm.utils';
import { FieldErrors } from '../FieldErrors';
import '../ClassForm.css';
import { useProvider } from 'data/ProviderContext';
import { sortCourseVersions, useFlags } from '../../../utils';
import {
    getAllCourseVersionsMockData,
    listAuthorizedCoursesMockData,
    listMajorVersionsMockData,
} from '../../../data/courseInformationV2.mock';

/**
 * This is V2 version of the Course Information component.
 * Main difference from V1 is that this allows selecting language before course version.
 * The new selection flow is: 1) Course, 2) Language, 3) Course Template, 4) Course Version
 * @component
 *
 * @param isNewClass Indicates if this is creating a new class or updating an existing class
 * @param fieldsInvalid Indicates if there is any invalid data from previous submission
 * @param courseId Course major version (aka. course template) ARN. e.g. arn:aws:learningcontent:us-east-1:360097715036:collection/ILT-DD-300-MLASMS-1
 * @param courseVersionId e.g. arn:aws:learningcontent:us-east-1:360097715036:collectionversion/ILT-DD-300-MLASMS-1:1.2.3-faa56070
 * @param langLocale Language of the course, e.g. en_US
 * @param initialCoursePk Grimsby course Pk. e.g. "c66b4f90-4202-486c-85cc-b58103af3146"
 * @param setClassData Function to update classData state in ClassForm component
 * @param setJamInformation Function to update jamInformation in ClassForm component
 *
 * @returns {Element}
 */
const CourseInformationV2 = ({
    isNewClass,
    fieldsInvalid,
    courseId,
    courseVersionId,
    langLocale,
    initialCoursePk,
    setClassData,
    setJamInformation,
}) => {
    const { formatMessage } = useIntl();

    const [courseMajorVersions, setCourseMajorVersions] = useState([]);
    const [
        courseMajorVersionsFilteredByLanguageDropDownOptions,
        setCourseMajorVersionsFilteredByLanguageDropDownOptions,
    ] = useState([]);

    const [courseVersionsDropDownOptions, setCourseVersionsDropDownOptions] = useState([]);
    const [courseVersionsLoading, setCourseVersionsLoading] = useState(false);

    const [courseVersionDetails, setCourseVersionDetails] = useState({});

    const [authorizedCoursesDropDownOptions, setAuthorizedCoursesDropDownOptions] = useState([]);

    const [coursePk, setCoursePk] = useState(initialCoursePk);

    const [supportedLangLocalesDropDownOptions, setSupportedLangLocalesDropDownOptions] = useState(
        []
    );

    const providerArn = useProvider()?.arn;
    const flags = useFlags();
    const useMockData = flags.enableCcsIntegrationWithMock; // https://issues.amazon.com/issues/BKR-6821

    // usePrevious determines if there was a change in input value which informs whether to reset
    // other dependent fields. This is helpful when we first load the form with persisted data and
    // not want to have current choices cleared out (as useEffect runs after the initial render).
    // When reading these variables after initial render, their values will be undefined.
    // Subsequent reading of their values will be defined.
    const prevCourseId = usePrevious(courseId);
    const prevLangLocale = usePrevious(langLocale);

    // Get courses.
    useEffect(() => {
        async function fetchData() {
            const authorizedCourses = useMockData
                ? fetchMockData('listAuthorizedCourses')
                : await listAuthorizedCourses(providerArn);

            setAuthorizedCoursesDropDownOptions(() =>
                authorizedCourses.map(({ id, title }) => ({
                    label: title,
                    value: id,
                }))
            );
        }

        if (isNewClass) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Reset selected course major version and lang locale when course changes.
    // Then get course major versions and set supported lang locales.
    useEffect(() => {
        if (!coursePk) return;

        async function fetchData() {
            const courseMajorVersions = useMockData
                ? fetchMockData('listMajorVersions', coursePk)
                : await listMajorVersions(coursePk, providerArn);
            const supportedLangLocalesForCourse = union(
                ...courseMajorVersions.map(({ supportedLangLocales }) => supportedLangLocales)
            );
            const formattedCourseMajorVersions = courseMajorVersions.map(
                ({
                    collectionArn: courseId,
                    collectionTitle: title,
                    supportedLangLocales: locale,
                }) => ({
                    courseId,
                    title,
                    locale,
                })
            );

            setSupportedLangLocalesDropDownOptions(() =>
                supportedLangLocalesForCourse.map(locale => ({
                    label: locale,
                    value: locale,
                }))
            );
            setCourseMajorVersions(formattedCourseMajorVersions);
            setCourseMajorVersionsFilteredByLanguageDropDownOptions(() =>
                formattedCourseMajorVersions
                    .map(({ courseId, title }) => ({
                        label: title,
                        value: courseId,
                    }))
                    .sort((a, b) => (a.label.toUpperCase() < b.label.toUpperCase() ? -1 : +1))
            );
        }

        if (isNewClass) {
            handleFormValueChange({
                value: '',
                setData: setClassData,
                keyPath: 'courseId',
            });
            handleFormValueChange({
                value: '',
                setData: setClassData,
                keyPath: 'langLocale',
            });
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coursePk]);

    // Reset selected course major version and course version when language changes.
    // Then filter course major versions down to those that support the selected language.
    useEffect(() => {
        async function fetchData() {
            const majorVersionsFilteredByLanguage = courseMajorVersions.filter(({ locale }) =>
                locale.includes(langLocale)
            );

            setCourseMajorVersionsFilteredByLanguageDropDownOptions(() =>
                majorVersionsFilteredByLanguage
                    .map(({ courseId, title }) => ({
                        label: title,
                        value: courseId,
                    }))
                    .sort((a, b) => (a.label.toUpperCase() < b.label.toUpperCase() ? -1 : +1))
            );
            setCourseVersionsDropDownOptions([]);
        }

        if (prevLangLocale) {
            handleFormValueChange({
                value: '',
                setData: setClassData,
                keyPath: 'courseId',
            });
            handleFormValueChange({
                value: '',
                setData: setClassData,
                keyPath: 'courseVersionId',
            });
            setCourseVersionDetails({});
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [langLocale]);

    // Reset selected course version when course major version changes. Then get corresponding course versions.
    useEffect(() => {
        if (!courseId) return;

        async function fetchData() {
            setCourseVersionsLoading(true);

            const courseVersions = useMockData
                ? sortCourseVersions(
                      fetchMockData('getAllCourseVersions', `${courseId}.${langLocale}`)
                  )
                : await getAllCourseVersions(courseId, providerArn, langLocale);

            setCourseVersionsDropDownOptions(() =>
                courseVersions.map(mapCoursesVersionsToDropDownOptions(formatMessage))
            );
            setCourseVersionsLoading(false);
        }

        if (prevCourseId) {
            handleFormValueChange({
                value: '',
                setData: setClassData,
                keyPath: 'courseVersionId',
            });
            setCourseVersionDetails({});
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId]);

    // Get course version details.
    useEffect(() => {
        if (!courseVersionId) return;

        async function fetchData() {
            const courseVersionDetails = await getCourseVersionDetails(
                courseVersionId,
                providerArn
            );

            setCourseVersionDetails(courseVersionDetails);
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseVersionId]);

    useEffect(() => {
        if (typeof setJamInformation === 'function') {
            setJamInformation({
                challengeSetID: courseVersionDetails?.jamConfig?.challengeSetID,
                contentTypes: courseVersionDetails?.contentTypes,
            });
        }
    }, [courseVersionDetails, setJamInformation]);

    return (
        <Form id="course-information" data-testid="course-information-V2">
            <Container
                header={<Header variant="h2">{formatMessage(messages.courseInfoHeader)}</Header>}
            >
                <SpaceBetween direction="vertical" size="m">
                    {isNewClass && (
                        <FormField stretch label={formatMessage(messages.coursesLabel)}>
                            <Select
                                ariaRequired
                                data-testid="course-information-courses"
                                disabled={!isNewClass}
                                selectedOption={getSelectedOptionInDropDownList(
                                    authorizedCoursesDropDownOptions,
                                    coursePk
                                )}
                                ariaLabel={formatMessage(messages.coursesPlaceholder)}
                                filteringType="auto"
                                filteringPlaceholder={formatMessage(
                                    messages.filterCoursePlaceholder
                                )}
                                placeholder={formatMessage(messages.coursesPlaceholder)}
                                options={authorizedCoursesDropDownOptions}
                                empty={
                                    <React.Fragment>
                                        {formatMessage(messages.courseEmptyLabel)}
                                    </React.Fragment>
                                }
                                onChange={({ detail }) => setCoursePk(detail.selectedOption.value)}
                            />
                        </FormField>
                    )}
                    <FormField stretch label={formatMessage(messages.courseLanguageLabel)}>
                        <Select
                            ariaRequired
                            data-testid="course-information-lang-locales"
                            invalid={!!fieldsInvalid.langLocale}
                            disabled={!isNewClass}
                            selectedOption={getSelectedOptionInDropDownList(
                                supportedLangLocalesDropDownOptions,
                                langLocale
                            )}
                            ariaLabel={formatMessage(messages.courseLanguagePlaceholder)}
                            options={supportedLangLocalesDropDownOptions}
                            empty={
                                <React.Fragment>
                                    {formatMessage(messages.courseLanguageEmptyLabel)}
                                </React.Fragment>
                            }
                            placeholder={formatMessage(messages.courseLanguagePlaceholder)}
                            selectedLabel={formatMessage(messages.selectedLabel)}
                            onChange={({ detail }) => {
                                handleFormValueChange({
                                    value: detail.selectedOption.value,
                                    setData: setClassData,
                                    keyPath: 'langLocale',
                                });
                            }}
                        ></Select>
                        <FieldErrors fieldsInvalid={fieldsInvalid} property="langLocale" />
                    </FormField>
                    <FormField stretch label={formatMessage(messages.courseTemplateLabel)}>
                        <Select
                            ariaRequired
                            data-testid="course-information-course-ids"
                            invalid={!!fieldsInvalid.courseId}
                            disabled={!isNewClass || !langLocale}
                            selectedOption={getSelectedOptionInDropDownList(
                                courseMajorVersionsFilteredByLanguageDropDownOptions,
                                courseId
                            )}
                            ariaLabel={formatMessage(messages.courseTemplatePlaceholder)}
                            options={courseMajorVersionsFilteredByLanguageDropDownOptions}
                            empty={
                                <React.Fragment>
                                    {formatMessage(messages.courseTemplateEmptyLabel)}
                                </React.Fragment>
                            }
                            placeholder={formatMessage(messages.courseTemplatePlaceholder)}
                            selectedLabel={formatMessage(messages.selectedLabel)}
                            onChange={({ detail }) => {
                                handleFormValueChange({
                                    value: detail.selectedOption.value,
                                    setData: setClassData,
                                    keyPath: 'courseId',
                                });
                            }}
                        ></Select>
                        <FieldErrors fieldsInvalid={fieldsInvalid} property="courseId" />
                    </FormField>
                    <FormField stretch label={formatMessage(messages.courseVersionLabel)}>
                        <Select
                            ariaRequired
                            data-testid="course-information-course-versions"
                            invalid={!!fieldsInvalid.courseVersionId}
                            selectedOption={getSelectedOptionInDropDownList(
                                courseVersionsDropDownOptions,
                                courseVersionId
                            )}
                            ariaLabel={formatMessage(messages.courseVersionPlaceholder)}
                            options={courseVersionsDropDownOptions}
                            empty={
                                <React.Fragment>
                                    {formatMessage(
                                        courseVersionsLoading
                                            ? messages.loadingField
                                            : messages.courseVersionEmptyLabel
                                    )}
                                </React.Fragment>
                            }
                            placeholder={formatMessage(messages.courseVersionPlaceholder)}
                            selectedLabel={formatMessage(messages.selectedLabel)}
                            onChange={({ detail }) =>
                                handleFormValueChange({
                                    value: detail.selectedOption.value,
                                    setData: setClassData,
                                    keyPath: 'courseVersionId',
                                })
                            }
                            filteringType="auto"
                            filteringPlaceholder={formatMessage(
                                messages.filterCourseVersionPlaceholder
                            )}
                            filteringLabel={formatMessage(messages.filterCourseVersionPlaceholder)}
                        ></Select>
                        <FieldErrors fieldsInvalid={fieldsInvalid} property="courseVersionId" />
                    </FormField>
                    <FormField stretch label={formatMessage(messages.courseVersionContentLabel)}>
                        <Input
                            data-testid="course-information-content-version"
                            className="class-form__semantic-version-input"
                            readonly
                            disabled
                            value={_get(courseVersionDetails, 'contentVersion', '')}
                        />
                    </FormField>
                </SpaceBetween>
            </Container>
        </Form>
    );
};

/**
 * Fetch mock data for course information.
 * @param operationName The operation name to fetch mock data for.
 * @param path The path to retrieve the data.
 * @returns mock data or empty array when there is no mock data for the given operation.
 */
export const fetchMockData = (operationName, path) => {
    switch (operationName) {
        case 'listAuthorizedCourses':
            return listAuthorizedCoursesMockData;
        case 'listMajorVersions':
            return listMajorVersionsMockData[path];
        case 'getAllCourseVersions':
            return _get(getAllCourseVersionsMockData, path, '');
        default:
            return [];
    }
};

/**
 * Get selected option in drop-down list.
 * @param dropDownListOptions a list of options for a drop-down. e.g. [{ label:"Option 1", value:"key-1" }, { label:"Option 2", value:"key-2" }]
 * @param key the key of the selected option. e.g. "key-1"
 * @returns the selected option or undefined if no option is selected.
 */
const getSelectedOptionInDropDownList = (dropDownListOptions, key) => {
    return dropDownListOptions.find(({ value }) => value === key) ?? undefined;
};

/**
 * Map course version data to drop-down option format.
 * @param formatMessage function to format message (for translations) exposed by react-intl library.
 * @returns options for the course versions drop-down list.
 */
const mapCoursesVersionsToDropDownOptions = formatMessage => ({ courseId, versionId }, index) => ({
    label:
        index === 0 ? `${versionId} (${formatMessage(messages.newestCourseVersion)})` : versionId,
    value: courseId,
});

export default CourseInformationV2;
