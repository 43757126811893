import { gql, useMutation, useQuery } from '@apollo/client';
import { prepareClient, usePrepareClient } from 'modules/supergraph/client';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { RoleType, useGetCurrentUserDelayed, UserStatus } from './useUserService';
import { useFlags } from '../utils';

const GET_CLASS_ATTENDANCE = gql`
    query QueryClassAttendance($learningActivityId: ID!) {
        classAttendance(searchCriteria: { learningActivityId: $learningActivityId }) {
            learner {
                id
                emailAddress
                lastName
            }
            learningActivityAttendanceStatus
            deliverySessionAttendanceRecords {
                id
                attendanceStatus
                attendanceDurationPercentage
                amsDeliverySession {
                    id
                    startTime
                }
            }
        }
    }
`;

const MARK_ATTENDANCE = gql`
    mutation markAttendance($input: MarkAttendanceInput!) {
        markAttendance(input: $input) {
            learner {
                id
            }
            attendanceStatus
            attendanceDurationPercentage
            amsDeliverySession {
                id
                learningActivity {
                    delivery_sessions {
                        id
                    }
                }
            }
        }
    }
`;

export const useGetClassAttendance = learningActivityId => {
    const ready = usePrepareClient();
    const { loading, error, data, refetch } = useQuery(GET_CLASS_ATTENDANCE, {
        variables: {
            learningActivityId,
        },
        skip: !ready,
        notifyOnNetworkStatusChange: true,
    });

    return {
        data: data?.classAttendance,
        isLoading: loading,
        error,
        refetch,
    };
};

export const useMarkClassAttendance = () => {
    const [markAttendance] = useMutation(MARK_ATTENDANCE);

    return async (markAttendanceInputs, onCompleteHandler, onErrorHandler) => {
        const clientReady = await prepareClient();
        if (clientReady) {
            Promise.all(
                _.map(markAttendanceInputs, markAttendanceInput => {
                    return markAttendance({
                        variables: {
                            input: markAttendanceInput,
                        },
                    }).catch(error => error);
                })
            )
                .then(onCompleteHandler)
                .catch(onErrorHandler);
        } else {
            return Promise.reject('Client is not ready');
        }
    };
};

export const useGetAttendanceListingEnabled = learningActivity => {
    const flags = useFlags();
    const [currentUser, setCurrentUser] = useState();
    const { getCurrentUser, called: invokedGetCurrentUser } = useGetCurrentUserDelayed();

    const attendanceMarkingEnabled = useMemo(() => {
        if (
            flags.attendanceMarking &&
            (learningActivity?.lms_type === 'myClass' ||
                learningActivity?.lms_type === 'CustomerLMS')
        ) {
            if (!invokedGetCurrentUser) {
                const getCurrentUserPromise = getCurrentUser();
                getCurrentUserPromise?.then(response => {
                    setCurrentUser(response.data.currentUser);
                });
            }
            return (
                _.eq(currentUser?.status, UserStatus.ACTIVE) &&
                _.includes([RoleType.SUPER_USER, RoleType.INTERNAL_EMPLOYEE], currentUser?.userRole)
            );
        }
        return undefined;
    }, [
        flags,
        learningActivity,
        currentUser,
        getCurrentUser,
        setCurrentUser,
        invokedGetCurrentUser,
    ]);

    return attendanceMarkingEnabled;
};
