import _ from 'lodash';

export const getAttendanceStatusLabelMap = stringBundle => {
    return {
        ATTENDED: stringBundle.statusAttended(),
        NOT_MARKED: stringBundle.statusNotMarked(),
        NO_SHOW: stringBundle.statusNoShow(),
        PARTIALLY_ATTENDED: stringBundle.statusPartiallyAttended(),
    };
};

export const isSessionAttendanceModified = (sessionAttendance, originalAttendanceRecord) => {
    const originalSessionAttendance = _.find(
        originalAttendanceRecord.deliverySessionAttendanceRecords,
        record => {
            return _.eq(sessionAttendance.deliverySessionId, record.deliverySessionId);
        },
        0
    );

    return !_.isEqual(originalSessionAttendance, sessionAttendance);
};

export const submitAttendance = ({
    markAttendanceFn,
    attendanceState,
    eventHandlers,
    attendanceRefetcher,
    stringBundle,
}) => {
    const sessionAttendancesWithUpdateBySessionId = buildSessionAttendanceInputs(
        attendanceState.learnersWithSessionAttendanceChanges,
        attendanceState.attendanceRecordList,
        attendanceState.attendanceRecordListReadOnly
    );

    const allMarkAttendanceInputs = [];
    _.forEach(sessionAttendancesWithUpdateBySessionId, (value, key) => {
        const learnerAttendanceInputArray = _.map(value, sessionAttendance => {
            return {
                learner: sessionAttendance.learner,
                attendanceDurationPercentage: sessionAttendance.attendanceDurationPercentage,
            };
        });
        const markAttendanceInput = {
            deliverySessionId: key,
            learnerAttendance: learnerAttendanceInputArray,
        };
        allMarkAttendanceInputs.push(markAttendanceInput);
    });

    const totalLearner = _.reduce(
        _.keys(sessionAttendancesWithUpdateBySessionId),
        (result, sessionId) => {
            _.forEach(sessionAttendancesWithUpdateBySessionId[sessionId], item =>
                result.add(item.learner)
            );
            return result;
        },
        new Set()
    ).size;

    const onMarkingAttendanceSuccess = values => {
        eventHandlers.onSubmitAttendanceChangeSuccess();
        attendanceRefetcher();
        eventHandlers.onSuccess(
            stringBundle.markAttendanceSuccessfulHeader(),
            stringBundle.markAttendanceSuccessfulText(totalLearner)
        );
    };

    const onMarkingAttendanceError = errors => {
        eventHandlers.onSubmitAttendanceChangeError();
        eventHandlers.onError(
            stringBundle.markAttendanceErrorHeader(),
            stringBundle.markAttendanceErrorText()
        );
    };

    eventHandlers.onSubmitAttendanceChange();
    markAttendanceFn(allMarkAttendanceInputs, onMarkingAttendanceSuccess, onMarkingAttendanceError);
};

const buildSessionAttendanceInputs = (
    learnersWithChange,
    attendanceRecordList,
    unmodifiedAttendanceRecordList
) => {
    const unmodifiedAttendanceRecordsByUserId = _.reduce(
        unmodifiedAttendanceRecordList,
        (resultMap, attendanceRecordRO) => {
            resultMap.set(attendanceRecordRO.learner.id, attendanceRecordRO);
            return resultMap;
        },
        new Map()
    );

    const attendanceRecordsWithChange = _.filter(attendanceRecordList, attendanceRecord =>
        learnersWithChange.has(attendanceRecord.learner.id)
    );
    const modifiedSessionAttendanceList = _.reduce(
        attendanceRecordsWithChange,
        (result, attendanceRecord) => {
            _.forEach(attendanceRecord.deliverySessionAttendanceRecords, sessionAttendance => {
                if (
                    isSessionAttendanceModified(
                        sessionAttendance,
                        unmodifiedAttendanceRecordsByUserId.get(attendanceRecord.learner.id)
                    )
                ) {
                    result.push({
                        deliverySessionId: sessionAttendance.deliverySessionId,
                        learner: attendanceRecord.learner.id,
                        attendanceDurationPercentage:
                            sessionAttendance.attendanceDurationPercentage,
                        attendanceStatus: sessionAttendance.attendanceStatus,
                    });
                }
            });
            return result;
        },
        []
    );

    return _.groupBy(modifiedSessionAttendanceList, item => item.deliverySessionId);
};
