import { groupBy, mapValues, sortBy } from 'lodash';
import messages from './IngressTable.messages';

export const isConsoleReady = labStatus => labStatus === 'CONSOLE_AVAILABLE';
export const isPreloadFailed = training =>
    training?.fulfillmentError?.name === 'BunsenProvisionFailure' ||
    (training?.fulfillmentStatus === 'error' &&
        training?.fulfillmentError?.name !== 'TooManyActiveLabs');
export const isExceedingLabLimit = name => name === 'TooManyActiveLabs';
export const isNotPreloading = training =>
    isPreloadFailed(training) ||
    isExceedingLabLimit(training?.fulfillmentError?.name) ||
    (training?.metaData?.labStatus &&
        !PRELOADING_LAB_STATUSES.includes(training?.metaData?.labStatus));

const NO_LAB_STATE = 'NO_LAB';

const filterCurrentBatch = (trainings, labId, batchId) =>
    trainings.filter(
        training => training.arn === labId && training?.requestClientToken?.includes(batchId)
    );

export const isAllConsoleReady = (trainings, labId, batchId) => {
    const filteredTraining = filterCurrentBatch(trainings, labId, batchId);
    return (
        filteredTraining.length > 0 &&
        filteredTraining.every(training => isConsoleReady(training?.metaData?.labStatus))
    );
};

export const isAllPreloadingEnded = (trainings, labId, batchId) => {
    const filteredTraining = filterCurrentBatch(trainings, labId, batchId);
    return (
        filteredTraining.length > 0 && filteredTraining.every(training => isNotPreloading(training))
    );
};

export const computeTableItems = (studentTrainings = [], labId) => {
    let accumulator = {};
    for (let i = 0; i < studentTrainings.length; i++) {
        let {
            userKey,
            studentNumber,
            arn,
            lastUpdated,
            firstName,
            lastName,
            metaData,
        } = studentTrainings[i];
        if (!accumulator[userKey]) {
            // no previous item found
            if (arn !== labId) {
                accumulator[userKey] = {
                    userKey,
                    studentNumber,
                    state: NO_LAB_STATE,
                };
                if (firstName && lastName) {
                    accumulator[userKey].firstName = firstName;
                    accumulator[userKey].lastName = lastName;
                }
            } else {
                accumulator[userKey] = studentTrainings[i];
                if (metaData?.labRegions?.length) {
                    accumulator[userKey].labRegion = metaData.labRegions[0];
                }
            }
        } else {
            const shouldReplaceWithItemInAcummulator =
                arn === labId &&
                (accumulator[userKey].state === NO_LAB_STATE ||
                    accumulator[userKey].lastUpdated < lastUpdated);
            if (shouldReplaceWithItemInAcummulator) {
                accumulator[userKey] = studentTrainings[i];
                if (metaData?.labRegions?.length) {
                    accumulator[userKey].labRegion = metaData.labRegions[0];
                }
            }
        }
    }
    return Object.values(accumulator);
};

export const isPooledLab = t => {
    const POOLED_TYPE = 'POOLED_SPL';
    if (!!t?.trainingType) {
        return t?.trainingType === POOLED_TYPE;
    }
    if ((t.requestClientToken || '').indexOf(POOLED_TYPE) > -1) {
        return true;
    }
    return false;
};

const RANK_NOT_PRESENT = 500;
const RANK_EMPTY_SEAT = 1000;
const RANK_POOLED_UNCLAIMED = 5000;

export const computeTableForPooledLabs = (studentTrainings = [], labArn, classroom = {}) => {
    const { classCapacity, studentRoster = [] } = classroom;
    const trainingsFromThisBlueprint = training => training?.arn === labArn;
    const pooledOrFromTheRoster = training =>
        isPooledLab(training) ||
        studentRoster.find(student => student.userKey === training.userKey);
    const filteredAndTransformed = studentTrainings
        .filter(trainingsFromThisBlueprint)
        .filter(pooledOrFromTheRoster)
        .map((training, i) => {
            if (isPooledLab(training)) {
                return {
                    ...training,
                    sortingRank: RANK_POOLED_UNCLAIMED + i,
                    userKey: `pooled-${training.userKey}`,
                };
            }
            return {
                ...training,
                sortingRank: training?.studentNumber || i,
                studentNumber: training?.studentNumber || i,
            };
        });
    const grouped = mapValues(groupBy(filteredAndTransformed, 'userKey'), trainings =>
        sortBy(trainings, 'lastUpdated').pop()
    );
    const rosterAssignedSeats = studentRoster.map((student, i) => {
        const sortingRank =
            (student?.userKey ? 0 : RANK_NOT_PRESENT) + (student.studentNumber || i + 1);
        const userKey = student.userKey || `unassigned-${sortingRank}`;
        return {
            ...student,
            userKey,
            sortingRank,
        };
    });
    const objectReducer = (accum, curr) => {
        accum[curr.userKey] = curr;
        return accum;
    };
    const withoutEmptySeats = {
        ...rosterAssignedSeats.reduce(objectReducer, {}),
        ...grouped,
    };
    const emptySeats = [
        ...new Array(Math.max(classCapacity - Object.keys(withoutEmptySeats).length, 0)),
    ]
        .map((_, i) => {
            const sortingRank = RANK_EMPTY_SEAT + i;
            return {
                userKey: `unregistered-${sortingRank}`,
                sortingRank,
                state: NO_LAB_STATE,
            };
        })
        .reduce(objectReducer, {});
    return Object.values({ ...emptySeats, ...withoutEmptySeats });
};

export const hiddenAlertBannerItem = {
    visible: false,
};

export const allAlertBannerItems = (formatMessage, alertBannerItemSet) => {
    return {
        errorAlertBannerItem: {
            type: 'error',
            content: formatMessage(messages.preloadFail),
            dismissible: true,
            dismissAriaLabel: formatMessage(messages.preloadDismiss),
            onDismiss: () => alertBannerItemSet(hiddenAlertBannerItem),
        },
        hasNotStartedAlertBannerItem: {
            type: 'error',
            content: formatMessage(messages.preloadClassNotStarted),
            dismissible: true,
            dismissAriaLabel: formatMessage(messages.preloadDismiss),
            onDismiss: () => alertBannerItemSet(hiddenAlertBannerItem),
        },
        successAlertBannerItem: {
            type: 'success',
            content: formatMessage(messages.preloadSuccess),
            dismissible: true,
            dismissAriaLabel: formatMessage(messages.preloadDismiss),
            onDismiss: () => alertBannerItemSet(hiddenAlertBannerItem),
        },
        inProgressAlertBannerItem: {
            type: 'info',
            content: formatMessage(messages.preloadInProgress),
            dismissible: true,
            dismissAriaLabel: formatMessage(messages.preloadDismiss),
            onDismiss: () => alertBannerItemSet(hiddenAlertBannerItem),
        },
    };
};

export const buildExceedLabLimitBannerItem = (formatMessage, bannerItemSet) => {
    return {
        type: 'warning',
        content: formatMessage(messages.preloadExceedLabLimit),
        dismissible: true,
        dismissAriaLabel: formatMessage(messages.preloadDismiss),
        onDismiss: () => bannerItemSet(hiddenAlertBannerItem),
    };
};

export const buildResetBannerItem = (formatMessage, regionBannerItemSet, region, labNumber) => {
    return {
        type: 'info',
        content: formatMessage(messages.resetRegion, {
            selectedRegion: region.value,
            labNumber: labNumber,
        }),
        dismissible: true,
        dismissAriaLabel: formatMessage(messages.preloadDismiss),
        onDismiss: () => regionBannerItemSet(hiddenAlertBannerItem),
    };
};

/**
 * Builds a banner item for failure to get a region
 */
export const buildRegionGetFailureItem = (formatMessage, regionBannerItemSet, labNumber) => {
    return {
        type: 'warning',
        content: formatMessage(messages.getRegionFailure, {
            labNumber: labNumber,
        }),
        dismissible: true,
        dismissAriaLabel: formatMessage(messages.preloadDismiss),
        onDismiss: () => regionBannerItemSet(hiddenAlertBannerItem),
    };
};

/**
 * Builds a banner item for failure to put a region
 */
export const buildRegionPutFailureItem = (formatMessage, regionBannerItemSet, labNumber) => {
    return {
        type: 'warning',
        content: formatMessage(messages.putRegionFailure, {
            labNumber: labNumber,
        }),
        dismissible: true,
        dismissAriaLabel: formatMessage(messages.preloadDismiss),
        onDismiss: () => regionBannerItemSet(hiddenAlertBannerItem),
    };
};

export const LabStatus = Object.freeze({
    NoLab: 'no-lab',
    Preloading: 'provisioning',
    Preloaded: 'preloaded',
    Ended: 'ended',
    Failed: 'failed',
    Unknown: 'unkown',
    Ready: 'ready',
    ExceedLabLimit: 'exceedLabLimit',
    Running: 'running',
    Expired: 'expired',
});

export const PRELOADING_LAB_STATUSES = [
    'PENDING',
    'INITIALIZING',
    'PROVISIONING',
    'RESOURCES_READY',
];

export const getLabStatus = lab => {
    const hasMetaData = !!lab.metaData;
    let labStatus;

    if (lab.state === NO_LAB_STATE) {
        labStatus = LabStatus.NoLab;
    } else if (
        lab.fulfillmentStatus === 'error' &&
        lab.fulfillmentError?.name === 'TooManyActiveLabs'
    ) {
        labStatus = LabStatus.ExceedLabLimit;
    } else if (lab.fulfillmentStatus === 'error') {
        labStatus = LabStatus.Failed;
    } else if (hasMetaData && PRELOADING_LAB_STATUSES.includes(lab.metaData.labStatus)) {
        labStatus = LabStatus.Preloading;
    } else if (hasMetaData && lab.metaData.labStatus === 'CONSOLE_AVAILABLE') {
        if (lab.bunsenLabStatus === 'RUNNING') {
            labStatus = LabStatus.Running;
        } else {
            labStatus = LabStatus.Preloaded;
        }
    } else if (hasMetaData && lab.metaData.labStatus === 'READY') {
        labStatus = LabStatus.Ready;
    } else if (hasMetaData && lab.metaData.labStatus === 'SESSION_ENDED') {
        if (lab.bunsenLabStatus === 'EXPIRED') {
            labStatus = LabStatus.Expired;
        } else {
            labStatus = LabStatus.Ended;
        }
    } else {
        labStatus = LabStatus.Unknown;
    }

    return labStatus;
};
