import React from 'react';
import { useIntl } from 'react-intl';

import { Table, Button } from '@amzn/awsui-components-react-v3';
import { messages } from './StudentRosterTable.messages';

const StudentRosterTable = ({
    roster,
    onRemoveStudent,
    isGrimsbyClassroom,
    subProvidersArnMap,
}) => {
    const { formatMessage } = useIntl();
    const columnDefinitions = [
        {
            id: 'firstName',
            header: formatMessage(messages.headerFirstName),
            cell: ({ firstName }) => firstName || '-',
        },
        {
            id: 'lastName',
            header: formatMessage(messages.headerLastName),
            cell: ({ lastName }) => lastName || '-',
        },
        {
            id: 'email',
            header: formatMessage(messages.headerEmail),
            cell: ({ email }) => email || '-',
        },
        {
            id: 'attendance',
            header: formatMessage(messages.headerStudentAttendance),
            cell: ({ hasAcceptedInvitation }) =>
                hasAcceptedInvitation ? formatMessage(messages.studentPresenceField) : '-',
        },
        ...(!!subProvidersArnMap
            ? [
                  {
                      id: 'provider',
                      header: formatMessage(messages.headerProvider),
                      cell: ({ eKitLicensedProviderArn }) =>
                          subProvidersArnMap[eKitLicensedProviderArn] || '-',
                  },
              ]
            : []),
        {
            id: 'accessControl',
            header: formatMessage(messages.headerAccessControl),
            cell: ({ email, hasAcceptedInvitation }) => (
                <Button
                    disabled={isGrimsbyClassroom}
                    data-testid={`${formatMessage(messages.accessControlButton)}`}
                    onClick={() => onRemoveStudent(email, hasAcceptedInvitation)}
                    variant="normal"
                >
                    {formatMessage(messages.accessControlButton)}
                </Button>
            ),
        },
    ];
    return (
        <Table
            resizableColumns
            variant="borderless"
            wrapLines
            empty={
                <div className="awsui-util-t-c">
                    <div className="awsui-util-pt-s awsui-util-mb-xs">
                        <b>{formatMessage(messages.noClasses)}</b>
                    </div>
                </div>
            }
            columnDefinitions={columnDefinitions}
            items={roster}
        ></Table>
    );
};

export default StudentRosterTable;
