import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { messages } from './ClassTable.messages';
import { paths } from 'utils';
import countriesMessages from 'components/classForm/countries.messages';

export const columnDefinitions = (formatMessage, isProviderClasses) => {
    const definitions = [
        {
            id: 'course-title',
            header: formatMessage(messages.courseTitle),
            cell: item => (
                <Link
                    data-testid="class-list__to-detail-page"
                    to={paths.classDetailPage(encodeURIComponent(item.classroomId))}
                >
                    {item.courseTitle}
                </Link>
            ),
            minWidth: '200px',
        },
        {
            id: 'start-date',
            header: formatMessage(messages.startDate),
            cell: item => item.startDate,
            minWidth: '125px',
        },
        {
            id: 'endsOn-date',
            header: formatMessage(messages.endsOnDate),
            cell: item => item.endsOn,
            minWidth: '125px',
        },
    ];

    if (isProviderClasses) {
        definitions.push(
            {
                id: 'instructor',
                header: formatMessage(messages.instructorList),
                cell: item => (
                    <Fragment>
                        {(item.instructors || []).map(email => (
                            <p key={email} className="awsui-util-p-n">
                                {email}
                            </p>
                        ))}
                    </Fragment>
                ),
                width: 300,
            },
            {
                id: 'timezone',
                header: formatMessage(messages.timezone),
                cell: item => item.locationData.timezone,
            },
            {
                id: 'location',
                header: formatMessage(messages.location),
                cell: item =>
                    item.country
                        ? formatMessage(countriesMessages[item.country])
                        : formatMessage(messages.virtualLocation),
            }
        );
    } else {
        definitions.push({
            id: 'delivery-method',
            header: formatMessage(messages.deliveryMethod),
            cell: item => formatMessage(messages[`${item.locationData.locationType}Location`]),
        });
    }

    return definitions;
};
