import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';
import 'moment/min/locales';
import { useClassroomData } from 'data/useClassroomData';
import { JamCard, LabCard, SectionHeader } from 'components';
import { isEmpty } from 'utils/helpers';
import './LabList.css';
import messages from './LabList.messages';
import { Box } from '@amzn/awsui-components-react-v3';

const { dayOf, labsHeaderLabel, jamHeaderLabel } = messages;

const translateDurationUnit = (value, unit, locale) => {
    // crlalva: to match Content API duration enum
    // https://code.amazon.com/packages/BunsenContent/blobs/mainline/--/src/libraries/data/blueprintVersionEnums.js
    if (unit === 'min') {
        unit = 'minute';
    }
    return moment
        .duration(value, unit)
        .locale(locale)
        .humanize();
};

const LabList = () => {
    const { locale, formatMessage } = useIntl();
    const { classroomId } = useParams();
    const { classData, isJamOnlyClassroom } = useClassroomData(classroomId);

    const labList = (classData.content || []).map((content, index) => ({
        ...content,
        index,
        title: content.title.replace(new RegExp(`^\\s*${classData.course.title}\\s*-\\s*`), ''),
        duration: translateDurationUnit(content.duration, content.durationUnit || 'minute', locale),
    }));

    const jamCards = (classData.jamTrainings?.trainings || []).map((training, index) => (
        <JamCard
            courseTitle={classData.course.title}
            jamIndex={index}
            jamTraining={training}
            key={training.contentId}
        />
    ));

    return isEmpty(classData) ? null : (
        <>
            {classData?.content?.length > 0 && (
                <>
                    <SectionHeader title={formatMessage(dayOf)} tagOverride="h2" variant="h1" />
                    <Box variant="awsui-key-label">{formatMessage(labsHeaderLabel)}</Box>
                </>
            )}
            <ol data-testid="lab-list" className="lab-list">
                {labList.map(lab => (
                    <LabCard {...lab} key={lab.title} />
                ))}
                {classData?.jamTrainings?.trainings?.length > 0 && (
                    <>
                        <SectionHeader
                            title="Jams"
                            variant="h1"
                            tagOverride="h2"
                            suppressNav={!isJamOnlyClassroom}
                        />
                        <Box variant="awsui-key-label">{formatMessage(jamHeaderLabel)}</Box>
                    </>
                )}
                {jamCards}
            </ol>
        </>
    );
};
export default LabList;
