import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { FormField, Input } from '@amzn/awsui-components-react';
import { Header, ColumnLayout, Link, Box } from '@amzn/awsui-components-react-v3';
import { MAX_CLASS_CAPACITY, MIN_CLASS_CAPACITY } from '../ClassForm.utils';
import { messages } from '../ClassForm.messages';
import { FieldErrors } from '../FieldErrors';
import { getErrorPropertyKey } from 'utils/gonzo-error-parse-utils';

const SubProviderList = ({
    subProviders = [],
    setClassData,
    classCapacity,
    capacityBySubProvider = {},
    setOpenCapacityInfoModal,
    fieldsInvalid = {},
    providerAvailableLicensesMap,
    isLoadingAvailableLicenses,
    isATPAndMultiGilmorePhase2Enabled,
}) => {
    const { formatMessage } = useIntl();
    const subProviderCapacityChangeHandler = useMemo(
        () => (updatedSubProviderArn, newSubProviderCapacity) => {
            setClassData(prevClassroomState => {
                const newClassroomState = calculateClassroomState(
                    prevClassroomState,
                    updatedSubProviderArn,
                    newSubProviderCapacity
                );
                return newClassroomState;
            });
        },
        [setClassData]
    );

    const providerRowElements = [];
    providerRowElements.push(
        <Header variant="h5" key={`providerNameHeader`}>
            {formatMessage(messages.capacityMultiProviderLabel)}
        </Header>
    );
    providerRowElements.push(
        <Header variant="h5" key={`numStudentInputHeader`}>
            {formatMessage(messages.numberOfStudentsLabel)}
        </Header>
    );
    if (isATPAndMultiGilmorePhase2Enabled)
        providerRowElements.push(
            <Header variant="h5" key={`availableLicencesHeader`}>
                {formatMessage(messages.availableLicensesLabel)}
            </Header>
        );

    for (let i = 0; i < subProviders.length; i++) {
        const regionProvider = subProviders[i];
        providerRowElements.push(
            <div key={`providerName_${i}`}>{regionProvider.providerName}</div>
        );
        providerRowElements.push(
            <FormField stretch={false} key={`ff_provider${i}`}>
                <Input
                    label={formatMessage(messages.numberOfStudentSubProviderInputLabel, {
                        subProviderName: regionProvider.providerName,
                    })}
                    id={`subProviderInput[${i}]`}
                    value={
                        capacityBySubProvider[regionProvider.providerArn]
                            ? capacityBySubProvider[regionProvider.providerArn].capacity
                            : '0'
                    }
                    onChange={e => {
                        subProviderCapacityChangeHandler(
                            regionProvider.providerArn,
                            e.detail.value
                        );
                    }}
                    type="number"
                    invalid={
                        !!fieldsInvalid[
                            getErrorPropertyKey('classCapacity', regionProvider.providerArn)
                        ]
                    }
                />
                <FieldErrors
                    fieldsInvalid={fieldsInvalid}
                    property={getErrorPropertyKey('classCapacity', regionProvider.providerArn)}
                />
            </FormField>
        );
        if (isATPAndMultiGilmorePhase2Enabled)
            providerRowElements.push(
                isLoadingAvailableLicenses ? (
                    <div>{formatMessage(messages.loadingField)}</div>
                ) : (
                    <div>
                        {!!providerAvailableLicensesMap
                            ? providerAvailableLicensesMap[regionProvider.providerArn]
                            : '-'}
                    </div>
                )
            );
    }
    providerRowElements.push(
        <Header variant="h4" key={`providerNumberTotalHeader`}>
            {formatMessage(messages.numberOfStudentParentProviderTotal)}
        </Header>
    );
    providerRowElements.push(
        <FormField stretch={false} key="ff_numStudentTotal">
            <Input
                label={formatMessage(messages.numberOfStudentParentProviderTotalInputLabel)}
                value={classCapacity}
                disabled={true}
                invalid={!!fieldsInvalid['classCapacity']}
            />
            <FieldErrors fieldsInvalid={fieldsInvalid} property="classCapacity" />
        </FormField>
    );

    return (
        <ColumnLayout disableGutters="true" key={'0'}>
            <Box margin={{ top: 'm', bottom: 's' }}>
                <Header
                    variant="h4"
                    description={formatMessage(messages.numberOfStudentsHint, {
                        max: MAX_CLASS_CAPACITY,
                        min: MIN_CLASS_CAPACITY,
                    })}
                    info={
                        <Link
                            variant="info"
                            onFollow={() => setOpenCapacityInfoModal(true)}
                            ariaLabel={formatMessage(messages.infoButtonOpenLabel)}
                        >
                            {formatMessage(messages.infoButtonText)}
                        </Link>
                    }
                >
                    {formatMessage(messages.numberOfStudentsLabel)}
                </Header>
            </Box>
            <ColumnLayout
                columns={isATPAndMultiGilmorePhase2Enabled ? 3 : 2}
                borders="horizontal"
                key="providerRows"
            >
                {providerRowElements}
            </ColumnLayout>
        </ColumnLayout>
    );
};

//Exported for testing
export const calculateClassroomState = (
    prevClassroomState,
    updatedSubProviderArn,
    newSubProviderCapacity
) => {
    const newClassroomState = {
        ...prevClassroomState,
    };

    let originalUpdatedRegionCapacity = 0;

    if (prevClassroomState.capacityBySubProvider[updatedSubProviderArn]) {
        originalUpdatedRegionCapacity = _.toNumber(
            prevClassroomState.capacityBySubProvider[updatedSubProviderArn].capacity
        );
    }

    if (_.isEmpty(_.trim(newSubProviderCapacity))) {
        newClassroomState.capacityBySubProvider[updatedSubProviderArn] = {
            capacity: _.toString(originalUpdatedRegionCapacity),
        };
    } else {
        let validatedNewRegionCapacity = _.isNaN(_.toNumber(newSubProviderCapacity))
            ? originalUpdatedRegionCapacity
            : _.toNumber(newSubProviderCapacity);
        if (validatedNewRegionCapacity < 0) {
            validatedNewRegionCapacity = originalUpdatedRegionCapacity;
        }
        newClassroomState.capacityBySubProvider[updatedSubProviderArn] = {
            capacity: _.toString(validatedNewRegionCapacity),
        };
    }

    const newClassCapacity = _.reduce(
        newClassroomState.capacityBySubProvider,
        (sum, value, key) => sum + _.toNumber(value.capacity),
        0
    );
    newClassroomState.classCapacity = _.toString(newClassCapacity);

    return newClassroomState;
};

export default SubProviderList;
