import { defineMessages } from 'react-intl.macro';

export const getStringBundle = formatMessage => {
    return {
        columnEmail: () => formatMessage(messages.emailColHeader),
        columnLastName: () => formatMessage(messages.lastNameColHeader),
        columnNSession: sessionIndex => formatMessage(messages.sessionColHeader, { sessionIndex }),
        columnClassAttendance: () => formatMessage(messages.classAttendanceColumnHeader),
        columnModified: () => formatMessage(messages.modifiedColHeader),
        counterText: numLearnerWithChange => {
            return formatMessage(
                numLearnerWithChange > 1 ? messages.counterPluralText : messages.counterSingleText,
                { numLearnerWithChange }
            );
        },
        courseTitle: () => formatMessage => formatMessage(messages.courseTitle),
        editModeDescription: () => formatMessage(messages.editModeDescription),
        errorHeaderDefault: () => formatMessage(messages.errorHeaderDefault),
        errorTextDefault: () => formatMessage(messages.errorTextDefault),
        errorTextNotAuthorized: () => formatMessage(messages.errorTextNotAuthorized),
        filteringNoMatch: () => formatMessage(messages.filteringNoMatch),
        filteringNoResult: () => formatMessage(messages.filteringNoResult),
        listControlGoToPage: pageNumber =>
            formatMessage(messages.listControlGoToPage, { pageNumber }),
        listControlNextPage: () => formatMessage(messages.listControlNextPage),
        listControlPreviousPage: () => formatMessage(messages.listControlPreviousPage),
        loadingText: () => formatMessage(messages.loadingText),
        markAttendanceInProgressText: () => formatMessage(messages.markAttendanceInProgressText),
        markAttendanceSuccessfulHeader: () =>
            formatMessage(messages.markAttendanceSuccessfulHeader),
        markAttendanceSuccessfulText: numUpdatedLearners =>
            formatMessage(
                numUpdatedLearners === 1
                    ? messages.markSingleAttendanceSuccessfulText
                    : messages.markAttendanceSuccessfulText,
                {
                    numUpdatedLearners,
                }
            ),
        markAttendanceErrorHeader: () => formatMessage(messages.markAttendanceErrorHeader),
        markAttendanceErrorText: () => formatMessage(messages.markAttendanceErrorText),
        resetText: () => formatMessage(messages.resetChangeText),
        sessionNth: sessionNumber => formatMessage(messages.sessionNth, { sessionNumber }),
        statusAttended: () => formatMessage(messages.statusAttended),
        statusNoShow: () => formatMessage(messages.statusNoShow),
        statusPartiallyAttended: () => formatMessage(messages.statusPartiallyAttended),
        statusNotMarked: () => formatMessage(messages.statusNotMarked),
        titleEditMode: () => formatMessage(messages.titleEditMode),
        titleViewMode: (course, classDescription) =>
            formatMessage(messages.titleViewMode, { course, classDescription }),
    };
};

export const messages = defineMessages({
    attendanceStatus: {
        id: 'AttendanceListing.courseTitle',
        defaultMessage: 'Course title',
    },
    counterSingleText: {
        id: 'AttendanceListing.counterSingleText',
        defaultMessage: '{numLearnerWithChange} row edited',
    },
    counterPluralText: {
        id: 'AttendanceListing.counterPluralText',
        defaultMessage: '{numLearnerWithChange} rows edited',
    },
    courseTitle: {
        id: 'AttendanceListing.courseTitle',
        defaultMessage: 'Course title',
    },
    emailColHeader: {
        id: 'AttendanceListing.emailColumnHeader',
        defaultMessage: 'Email',
    },
    errorHeaderDefault: {
        id: 'AttendanceListing.errorHeaderDefault',
        defaultMessage: 'Error',
    },
    errorTextDefault: {
        id: 'AttendanceListing.errorTextDefault',
        defaultMessage: 'There was an error.',
    },
    errorTextNotAuthorized: {
        id: 'AttendanceListing.errorTextNotAuthorized',
        defaultMessage: 'There was an error.',
    },
    lastNameColHeader: {
        id: 'AttendanceListing.lastNameColumnHeader',
        defaultMessage: 'Last Name',
    },
    listControlNextPage: {
        id: 'AttendanceListing.listControlNextPage',
        defaultMessage: 'Next page',
    },
    listControlPreviousPage: {
        id: 'AttendanceListing.listControlPreviousPage',
        defaultMessage: 'Previous page',
    },
    listControlGoToPage: {
        id: 'AttendanceListing.listControlGoToPage',
        defaultMessage: 'Go to page {pageNumber}',
    },
    sessionColHeader: {
        id: 'AttendanceListing.sessionColumnHeader',
        defaultMessage: 'Session {sessionIndex} Attendance',
    },
    classAttendanceColumnHeader: {
        id: 'AttendanceListing.classAttendanceColumnHeader',
        defaultMessage: 'Class Attendance',
    },
    modifiedColHeader: {
        id: 'AttendanceListing.modifiedColHeader',
        defaultMessage: 'Modified?',
    },
    editModeDescription: {
        id: 'AttendanceListing.editModeDescription',
        defaultMessage:
            'Please enter session attendance per learner. Class attendance is automatically determined based on session attendance',
    },
    filteringNoMatch: {
        id: 'AttendanceListing.filteringNoMatch',
        defaultMessage: 'No match',
    },
    filteringNoResult: {
        id: 'AttendanceListing.filteringNoResult',
        defaultMessage: 'Classroom roster is empty',
    },
    loadingText: {
        id: 'AttendanceListing.loadingText',
        defaultMessage: 'Loading attendance information...',
    },
    sessionNth: {
        id: 'AttendanceListing.sessionNth',
        defaultMessage: 'Session #{sessionNumber}',
    },
    statusAttended: {
        id: 'AttendanceListing.statusAttended',
        defaultMessage: 'Attended',
    },
    statusPartiallyAttended: {
        id: 'AttendanceListing.statusPartiallyAttended',
        defaultMessage: 'Partially Attended',
    },
    statusNoShow: {
        id: 'AttendanceListing.statusNoShow',
        defaultMessage: 'No Show',
    },
    statusNotMarked: {
        id: 'AttendanceListing.statusNotMarked',
        defaultMessage: 'Not Marked',
    },
    titleEditMode: {
        id: 'AttendanceListing.titleEditMode',
        defaultMessage: 'Update Attendance',
    },
    titleViewMode: {
        id: 'AttendanceListing.titleViewMode',
        defaultMessage: 'Showing Attendance for {course} ({classDescription})',
    },
    resetChangeText: {
        id: 'AttendanceListing.resetChangeText',
        defaultMessage: 'reset',
    },
    markAttendanceErrorHeader: {
        id: 'AttendanceListing.markAttendanceErrorHeader',
        defaultMessage: 'Attendance Update Failed',
    },
    markAttendanceErrorText: {
        id: 'AttendanceListing.markAttendanceErrorText',
        defaultMessage: 'There was an error while updating attendance.',
    },
    markAttendanceInProgressText: {
        id: 'AttendanceListing.markAttendanceInProgressText',
        defaultMessage: 'Submitting Attendance...',
    },
    markAttendanceSuccessfulHeader: {
        id: 'AttendanceListing.markAttendanceSuccessfulHeader',
        defaultMessage: 'Attendance Update Successful',
    },
    markAttendanceSuccessfulText: {
        id: 'AttendanceListing.markAttendanceSuccessfulText',
        defaultMessage: 'Updated attendance for {numUpdatedLearners} learners',
    },
    markSingleAttendanceSuccessfulText: {
        id: 'AttendanceListing.markSingleAttendanceSuccessfulText',
        defaultMessage: 'Updated attendance for {numUpdatedLearners} learner',
    },
});
