import React from 'react';
import { useUserInfo } from '../../utils';
import { messages } from './UserKeyDisplay.messages';
import { useIntl } from 'react-intl';
import './UserKeyDisplay.scss';
import { Box } from '@amzn/awsui-components-react-v3';

const UserKeyDisplay = () => {
    const { userKey } = useUserInfo();
    const { formatMessage } = useIntl();
    if (!userKey) return null;
    return (
        <Box data-testid="user-key-display">
            {formatMessage(messages.userKey)} {userKey}
        </Box>
    );
};

export default UserKeyDisplay;
