/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClassroom = /* GraphQL */ `
  query GetClassroom($classroomId: String!, $providerArn: String) {
    getClassroom(classroomId: $classroomId, providerArn: $providerArn) {
      classroom {
        classroomId
        classroomArn
        langLocale
        courseId
        providerArn
        courseTitle
        courseAbstract
        courseDescription
        courseLevel
        startsOn
        endsOn
        locationData {
          timezone
          displayName
          longitude
          latitude
          locationType
          virtualUrl
          physicalAddress {
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
            __typename
          }
          source
          __typename
        }
        studentGuide {
          custom_vbid
          __typename
        }
        callsToAction {
          locationType
          url
          label
          actionType
          icon
          iconPosition
          __typename
        }
        accessCodes {
          accessCode
          accessCodeCapacity
          accessCodeCapacityUsed
          __typename
        }
        classCapacity
        studentCount
        supportedRegions
        createdBy
        accessType
        isCancellable
        subProviderCapacity {
          subProviderArn
          capacity
          __typename
        }
        learningActivityID
        __typename
      }
      course {
        courseId
        title
        description
        langLocale
        abstract
        level
        __typename
      }
      content {
        contentId
        title
        description
        level
        duration
        durationUnit
        isActive
        supportedRegions
        __typename
      }
      jamTrainings {
        trainings {
          context
          trainingId
          trainingType
          contentId
          requestClientToken
          isActive
          fulfillmentStatus
          fulfillmentError {
            message
            code
            __typename
          }
          created
          lastUpdate
          metaData {
            eventId
            eventCode
            eventStatus
            testCloneEventId
            testCloneEventCode
            testCloneEventSuffix
            startsOn
            endsOn
            timezone
            maxTeamSize
            maxExpectedParticipants
            eventURL
            testEventURL
            adminEventURL
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const getStudentTrainingsByClassroomId = /* GraphQL */ `
  query GetStudentTrainingsByClassroomId(
    $classroomId: String!
    $accessType: AccessType
  ) {
    getStudentTrainingsByClassroomId(
      classroomId: $classroomId
      accessType: $accessType
    ) {
      studentTrainings {
        lastUpdated
        metaData {
          preferredLangLocale
          labId
          labUrl
          labStatus
          region
          labRegions
          targetRegions
          __typename
        }
        trainingId
        context
        created
        requestClientToken
        arn
        state
        fulfillmentStatus
        fulfillmentError {
          name
          message
        }
        bunsenLabStatus
        userKey
        studentNumber
        firstName
        lastName
        email
        trainingType
        __typename
      }
      __typename
    }
  }
`;
export const getIngressUrl = /* GraphQL */ `
  query GetIngressUrl($input: GetIngressUrlInput!) {
    getIngressUrl(input: $input) {
      labId
      consoleUrl
      endsOn
      status
      __typename
    }
  }
`;
export const getCourseVersions = /* GraphQL */ `
  query GetCourseVersions($input: GetCourseVersionsInput!) {
    getCourseVersions(input: $input) {
      courseVersions {
        courseId
        versionId
        createdOn
        status
        availability
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCourseVersion = /* GraphQL */ `
  query GetCourseVersion($courseVersionId: String!, $providerArn: String) {
    getCourseVersion(
      courseVersionId: $courseVersionId
      providerArn: $providerArn
    ) {
      courseVersion {
        courseVersionId
        createdOn
        availability
        status
        courseName
        supportedLocales
        blueprintData {
          blueprintName
          arn
          __typename
        }
        resourceData {
          locale
          description
          title
          __typename
        }
        contentVersion
        jamConfig {
          challengeSetID
          __typename
        }
        contentTypes
        __typename
      }
      __typename
    }
  }
`;
export const getCourseArns = /* GraphQL */ `
  query GetCourseArns {
    getCourseArns {
      courses {
        courseId
        title
        __typename
      }
      __typename
    }
  }
`;
export const getProviderArns = /* GraphQL */ `
  query GetProviderArns {
    getProviderArns {
      providers
      __typename
    }
  }
`;
export const getProvider = /* GraphQL */ `
  query GetProvider($input: GetProviderInput!) {
    getProvider(input: $input) {
      provider {
        name
        arn
        type
        subProviderData {
          providerName
          providerType
          providerArn
          providerMetaData {
            isParentProvider
            parentProviderArn
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getServiceSchedule = /* GraphQL */ `
  query GetServiceSchedule($input: GetServiceScheduleInput!) {
    getServiceSchedule(input: $input) {
      schedules {
        service
        domain
        endTime
        startTime
        event
        type
        __typename
      }
      __typename
    }
  }
`;
export const getProviderReport = /* GraphQL */ `
  query GetProviderReport($input: GetProviderReportInput!) {
    getProviderReport(input: $input) {
      presignedUrl
      __typename
    }
  }
`;
export const getTrainingConfiguration = /* GraphQL */ `
  query GetTrainingConfiguration($input: GetTrainingConfigurationInput!) {
    getTrainingConfiguration(input: $input) {
      context
      contentArn
      contentType
      configuration {
        targetRegions
        __typename
      }
      __typename
    }
  }
`;
export const listUserRelationships = /* GraphQL */ `
  query ListUserRelationships($input: ListUserRelationshipsInput!) {
    listUserRelationships(input: $input) {
      relationships
      __typename
    }
  }
`;
export const listClassroomRelationships = /* GraphQL */ `
  query ListClassroomRelationships($input: ListClassroomRelationshipsInput!) {
    listClassroomRelationships(input: $input) {
      classroomUsers {
        email
        userKey
        firstName
        lastName
        hasAcceptedInvitation
        eKitLicensedProviderArn
        __typename
      }
      emails
      __typename
    }
  }
`;
export const listUserClassrooms = /* GraphQL */ `
  query ListUserClassrooms($input: ListClassroomsInput!) {
    listUserClassrooms(input: $input) {
      classrooms {
        classroomArn
        providerArn
        langLocale
        courseId
        startsOn
        endsOn
        locationData {
          timezone
          displayName
          longitude
          latitude
          locationType
          virtualUrl
          physicalAddress {
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
            __typename
          }
          source
          __typename
        }
        courseTitle
        createdByEmail
        instructorEmails
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listProviderClassrooms = /* GraphQL */ `
  query ListProviderClassrooms($input: ListClassroomsInput!) {
    listProviderClassrooms(input: $input) {
      classrooms {
        classroomArn
        providerArn
        langLocale
        courseId
        startsOn
        endsOn
        locationData {
          timezone
          displayName
          longitude
          latitude
          locationType
          virtualUrl
          physicalAddress {
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
            __typename
          }
          source
          __typename
        }
        courseTitle
        createdByEmail
        instructorEmails
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listProviderReports = /* GraphQL */ `
  query ListProviderReports($input: ListProviderReportsInput!) {
    listProviderReports(input: $input) {
      iltReports {
        reportId
        reportName
        reportDate
        reportDescription
        createdDate
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses($input: ListCoursesInput!) {
    listCourses(input: $input) {
      courses {
        title
        id
        __typename
      }
      __typename
    }
  }
`;
export const listMajorCourseVersions = /* GraphQL */ `
  query ListMajorCourseVersions($input: ListMajorCourseVersionsInput) {
    listMajorCourseVersions(input: $input) {
      collections {
        collectionArn
        collectionTitle
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listInstructorClassroomsV2 = /* GraphQL */ `
  query ListInstructorClassroomsV2($input: ListInstructorClassroomsV2Input!) {
    listInstructorClassroomsV2(input: $input) {
      classrooms {
        classroomArn
        providerArn
        langLocale
        courseId
        startsOn
        endsOn
        locationData {
          timezone
          displayName
          longitude
          latitude
          locationType
          virtualUrl
          physicalAddress {
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
            __typename
          }
          source
          __typename
        }
        courseTitle
        createdByEmail
        instructorEmails
        __typename
      }
      count
      countType
      aggregations {
        courseTitle {
          items {
            value
            count
            __typename
          }
          countType
          __typename
        }
        langLocale {
          items {
            value
            count
            __typename
          }
          countType
          __typename
        }
        country {
          items {
            value
            count
            __typename
          }
          countType
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listProviderClassroomsV2 = /* GraphQL */ `
  query ListProviderClassroomsV2($input: ListProviderClassroomsV2Input!) {
    listProviderClassroomsV2(input: $input) {
      classrooms {
        classroomArn
        providerArn
        langLocale
        courseId
        startsOn
        endsOn
        locationData {
          timezone
          displayName
          longitude
          latitude
          locationType
          virtualUrl
          physicalAddress {
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
            __typename
          }
          source
          __typename
        }
        courseTitle
        createdByEmail
        instructorEmails
        __typename
      }
      count
      countType
      aggregations {
        courseTitle {
          items {
            value
            count
            __typename
          }
          countType
          __typename
        }
        langLocale {
          items {
            value
            count
            __typename
          }
          countType
          __typename
        }
        country {
          items {
            value
            count
            __typename
          }
          countType
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const requiresReset = /* GraphQL */`
  query RequiresReset {
    requiresReset {
      requiresReset
    }
  }
`;

export const courseVirtualSKUInfo = /* GraphQL */ `
  query CourseVirtualSKUInfo($input: CourseVirtualSKUInfoInput!) {
    courseVirtualSKUInfo(input: $input) {
        productId
        availableQuantity
        __typename
    }
  }
`;
