import { Auth, Cache } from 'aws-amplify';
import environment from '../../.config/environment';
import { isLocalhost } from '../../utils/helpers';
import { configureAmplify } from './authUtils';

const { LOG_LEVEL } = environment(window);

/**
 * Enable Amplify debugging in localhost for better transparency
 */
if (isLocalhost) {
    window.LOG_LEVEL = LOG_LEVEL;
}

/**
 * Redirects to the Cognito login page.
 * NOTE: redirec_url must match callback url defined in cognito has to
 * match one of the callback URL's defined in the Cognito console. Since we lose the class path
 * we store here to redirect after the Cognito redirect
 */
export function redirectToLoginPage(verifyEmail = false, idpName = 'COGNITO') {
    sessionStorage.setItem('redirect_pathname', window.location.pathname);
    configureAmplify(verifyEmail);
    Auth.federatedSignIn({ provider: idpName });
}

/**
 * Checks if there is a currrent authenticated user.
 * Due to Amplify expecting OIDC to be set by authenticating with identity pools we must
 * manage federatedInfo here. Auth.currentAuthenticatedUser returns a valid jwtToken and if different
 * from Cache federatedInfo, update the cache with the valid token
 */
export async function hasValidToken() {
    try {
        const cognitoIdToken = (await Auth.currentAuthenticatedUser({ bypassCache: true }))
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken();
        const currentToken = Cache.getItem('federatedInfo').token;
        if (currentToken !== cognitoIdToken) {
            Cache.setItem('federatedInfo', { token: cognitoIdToken });
        }
        return true;
    } catch (e) {
        return false;
    }
}
