import moment from 'moment-timezone';
import { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl.macro';
import shortUUID from 'short-uuid';
import _ from 'lodash';

import {
    getDateTimeData,
    defaultDateTimeData,
    dateTimeToUnix,
    isSameDay,
} from 'utils/timestamp-utils';
import countries from './countries.messages';
import * as formMessages from './ClassForm.messages';
import { paths } from '../../utils';
import { JAM_DURATION, jamEventValidator } from './jamValidation';

// https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
// eslint-disable-next-line
export const EMAIL_REGEX = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export const NEW_LINE_SPACE_REGEX = /\r|\n|\s/;

const accessCodeUUID = shortUUID('123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ!()_');

const messages = defineMessages({
    virtualClassLabel: {
        id: 'classForm.virtualLabel',
        defaultMessage: 'Virtual classroom',
    },
    physicalClassLabel: {
        id: 'classForm.physicalClassLabel',
        defaultMessage: 'In-person classroom',
    },
    newestCourseVersion: {
        id: 'classForm.newestCourseVersion',
        defaultMessage: 'newest',
    },
    rosterClassAccessLabel: {
        id: 'classForm.rosterClassAccessLabel',
        defaultMessage: 'Student roster',
    },
    accessCodeClassAccessLabel: {
        id: 'classForm.accessCodeClassAccessLabel',
        defaultMessage: 'Access code',
    },
});

export const LOCATION_TYPES = {
    physical: 'physical',
    virtual: 'virtual',
};

export const LOCATION_SOURCES = {
    DEFAULT: 'DEFAULT',
    LADS: 'LADS',
};

export const MAX_CLASS_CAPACITY = 300;
export const MIN_CLASS_CAPACITY = 0;

export const CLASS_ACCESS_TYPES = {
    roster: 'STUDENT_ROSTER',
    accessCode: 'ACCESS_CODE',
};

/**
 * Helper method to get formatted react-intl message for class type
 * Will be either `In-Person Classroom` or `Virtual Classroom`
 *
 * @param {String} type LOCATION_TYPE, should be either physical or virtual
 * @return Class location label for physical or virtual classroom, or undefined
 */
export const useLocationTypeLabel = type => {
    const { formatMessage } = useIntl();

    if (type === LOCATION_TYPES.physical) {
        return formatMessage(messages.physicalClassLabel);
    }
    if (type === LOCATION_TYPES.virtual) {
        return formatMessage(messages.virtualClassLabel);
    }
};

export const useAccessTypeLabel = type => {
    const { formatMessage } = useIntl();

    if (type === CLASS_ACCESS_TYPES.roster) {
        return formatMessage(messages.rosterClassAccessLabel);
    }
    if (type === CLASS_ACCESS_TYPES.accessCode) {
        return formatMessage(messages.accessCodeClassAccessLabel);
    }
};

export const handleFormValueChange = ({ value, setData, keyPath }) =>
    setData(prevState => ({
        ...prevState,
        [keyPath]: value,
    }));

export const mapCoursesToSelectObject = ({ courseId, title }) => ({
    id: courseId,
    label: title,
});

export const mapCoursesVersionsToSelectObject = formatMessage => (
    { courseId, versionId },
    index
) => ({
    id: courseId,
    label:
        index === 0 ? `${versionId} (${formatMessage(messages.newestCourseVersion)})` : versionId,
});

export const mapLocalesToSelectObject = locale => ({
    id: locale,
    label: locale,
});

export const prepareCountriesForSelect = formatMessage => {
    return Object.keys(countries)
        .reduce((acc, cur) => {
            return [...acc, { id: cur, label: formatMessage(countries[cur]) }];
        }, [])
        .sort((a, b) => {
            const [labelA, labelB] = [a.label, b.label];
            if (labelA < labelB) return -1;
            if (labelA > labelB) return 1;
            return 0;
        });
};

export const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const transformIncomingDataToFormData = data => {
    if (!data) return {};
    const {
        courseId,
        langLocale,
        instructors = [],
        locationData = {},
        classCapacity = 0,
        accessType = CLASS_ACCESS_TYPES.accessCode,
        subProviderCapacity = [],
        learningActivityID = '',
    } = data;
    const {
        locationType = LOCATION_TYPES.virtual,
        physicalAddress,
        virtualUrl,
        timezone,
        source = LOCATION_SOURCES.DEFAULT,
    } = locationData;
    const { addressLine1, addressLine2, city, state, postalCode, country } = physicalAddress || {};

    let capacityBySubProvider;
    if (subProviderCapacity && subProviderCapacity.length > 0) {
        capacityBySubProvider = subProviderCapacity.reduce((allSubProvidersMap, subProvider) => {
            allSubProvidersMap[subProvider.subProviderArn] = {
                capacity: _.toString(subProvider.capacity),
            };
            return allSubProvidersMap;
        }, {});
    }

    return {
        ...{
            langLocale,
            locationType,
            addressLine1,
            addressLine2,
            city,
            state,
            postalCode,
            country,
            virtualUrl,
            timezone,
            instructors,
            courseId: courseId
                .match(/(arn:aws:learningcontent:[^:]+:\d+:collectionversion\/[^:]+):/)[1]
                .replace('version', ''),
            courseVersionId: courseId,
            classCapacity: `${classCapacity}`,
            accessType,
            learningActivityID,
            source,
        },
        ...(!_.isEmpty(capacityBySubProvider) ? { capacityBySubProvider } : {}),
    };
};

export const transformIncomingDataToDateTimeData = data => {
    if (!data) return defaultDateTimeData;
    const { locationData = {}, startsOn, endsOn } = data;
    const { timezone } = locationData;
    return getDateTimeData({ startsOn, endsOn, timezone });
};

export const putUserEmailFirstInArray = (instructors, userEmail) => {
    const userIndex = instructors.findIndex(email => email === userEmail);
    if (userIndex === -1) return instructors;

    const copy = [...instructors];
    copy.splice(userIndex, 1);
    copy.unshift(userEmail);
    return copy;
};

const filterEmptiesFromArray = arr => {
    if (!arr || !Array.isArray(arr)) return undefined;
    return arr.filter(i => i.trim());
};

export const prepareFormData = (
    {
        timezone,
        courseVersionId: courseId,
        classCapacity,
        accessCode: incomingAccessCode,
        langLocale,
        virtualUrl,
        locationType,
        addressLine1,
        addressLine2,
        city,
        state,
        postalCode,
        country,
        startsOn,
        endsOn,
        instructors,
        providerArn,
        classroomId,
        accessType = CLASS_ACCESS_TYPES.accessCode,
        capacityBySubProvider,
        authorizedSubProviderArns = [],
        jamData,
        learningActivityID = '',
        source = LOCATION_SOURCES.DEFAULT,
    },
    { flags } = {}
) => {
    let mutationVars = {};

    const locationData =
        locationType === LOCATION_TYPES.physical
            ? {
                  physicalAddress: {
                      addressLine1,
                      ...(addressLine2 && { addressLine2 }),
                      city,
                      state,
                      postalCode,
                      country,
                  },
              }
            : { virtualUrl };
    const sanitizedInstructors = filterEmptiesFromArray(instructors);

    mutationVars = {
        clientRequestToken: shortUUID.generate(),
        instructors: sanitizedInstructors,
        classroom: {
            startsOn,
            endsOn,
            courseId,
            providerArn,
            langLocale,
            locationData: {
                ...locationData,
                timezone,
                locationType,
            },
        },
    };
    if (flags && flags.studentRoster) {
        mutationVars.classroom.accessType = accessType;
        mutationVars.classCapacity = parseInt(classCapacity);
    }
    if (flags && flags.aurousIntegration) {
        mutationVars.classroom.locationData.source = source || LOCATION_SOURCES.DEFAULT;
        mutationVars.classroom.learningActivityID = learningActivityID || '';
    }

    if (!classroomId) {
        // params specific to new classes
        mutationVars.accessCode = accessCodeUUID.generate();
        mutationVars.classCapacity = parseInt(classCapacity);
    } else {
        mutationVars.classroomId = classroomId;
        if (incomingAccessCode && parseInt(classCapacity) >= MIN_CLASS_CAPACITY) {
            mutationVars.classCapacity = parseInt(classCapacity);
            mutationVars.accessCode = incomingAccessCode;
        }
    }

    if (flags && flags.multiGilmoreId) {
        if (capacityBySubProvider) {
            const capacityBySubProviderInNumber = _.transform(
                capacityBySubProvider,
                (result, value, key) => {
                    const userIsAuthorizedForSubProvider = authorizedSubProviderArns.includes(key);

                    if (userIsAuthorizedForSubProvider) {
                        result.push({
                            subProviderArn: key,
                            capacity: _.toNumber(value.capacity),
                        });
                    }
                },
                []
            );
            mutationVars.subProviderCapacity = capacityBySubProviderInNumber;
        }
    }

    if (jamData?.challengeId) {
        const jamStartsOn = dateTimeToUnix(jamData.startDate, jamData.startTime, timezone);
        const jamEndsOn = jamStartsOn + JAM_DURATION * 3600;
        mutationVars.jamEventConfig = {
            startsOn: jamStartsOn,
            endsOn: jamEndsOn,
            teamSize: parseInt(jamData.teamSize, 10),
            trainingId: jamData?.trainingId,
        };
        if (jamData.isJamOnly) {
            mutationVars.classroom.startsOn = jamStartsOn;
            mutationVars.classroom.endsOn = jamEndsOn;
        }
    }

    return mutationVars;
};

export const validationErrorCodeMap = {
    required: 'Required',
    length: 'Length',
    numLimit: 'NumLimit',
    pattern: 'Pattern',
    type: 'Type',
    enum: 'Enum',
    invalidInclusion: 'InvalidInclusion',
    unauthorized: 'Unauthorized',
    invalid: 'Invalid',
};

// NOTE: the order of these fields need to reflect the order in the dom
// as it will impact automatically scrolling to the first field with errors
export const formFields = [
    'courseId',
    'courseVersionId',
    'langLocale',
    'instructors',
    'classCapacity',
    'timezone',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
    'virtualUrl',
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'postalCode',
    'country',
];
const fieldsToValidate = formFields.filter(field => !['addressLine2'].includes(field));

/** @type {Object.<string, boolean>} */
export const initialFieldsInvalidState = fieldsToValidate.reduce(
    (acc, field) => ({ ...acc, [field]: false }),
    {}
);

const requiredField = val =>
    val === undefined || val.length === 0 ? [{ code: validationErrorCodeMap.required }] : false;

const fieldValidators = {
    courseId: requiredField,
    courseVersionId: requiredField,
    langLocale: requiredField,
    instructors: (val, { userInfo }) => {
        const errors = val
            .map(v => {
                if (!v) {
                    return { code: validationErrorCodeMap.required, values: [v] };
                }
                if (!EMAIL_REGEX.test(v)) {
                    return { code: validationErrorCodeMap.pattern, values: [v] };
                }
                if (userInfo?.userIsTrainingCoordinator && v === userInfo?.email) {
                    return { code: validationErrorCodeMap.invalidInclusion, values: [v] };
                }
                return false;
            })
            .filter(err => err);
        return errors.length ? errors : false;
    },
    classCapacity: val => {
        if (val < MIN_CLASS_CAPACITY || val > MAX_CLASS_CAPACITY) {
            return [{ code: validationErrorCodeMap.numLimit }];
        } else {
            return false;
        }
    },
    startDate: requiredField,
    endDate: requiredField,
    startTime: requiredField,
    endTime: requiredField,
    timezone: requiredField,
    virtualUrl: (val, { locationType }) =>
        locationType === LOCATION_TYPES.virtual && requiredField(val),
    addressLine1: (val, { locationType }) =>
        locationType === LOCATION_TYPES.physical && requiredField(val),
    city: (val, { locationType }) => locationType === LOCATION_TYPES.physical && requiredField(val),
    state: (val, { locationType }) =>
        locationType === LOCATION_TYPES.physical && requiredField(val),
    postalCode: (val, { locationType }) =>
        locationType === LOCATION_TYPES.physical && requiredField(val),
    country: (val, { locationType }) =>
        locationType === LOCATION_TYPES.physical && requiredField(val),
    learningActivityID: (val, { source }) => source === LOCATION_SOURCES.LADS && requiredField(val),
};

export const MINIMUM_DURATION_DAYS = 28;

export const isClassDurationLessThanRecommended = (startDate, startTime, endDate, endTime) => {
    const [start, end] = [
        { date: startDate, time: startTime },
        { date: endDate, time: endTime },
    ].map(({ date, time }) => moment(`${date} ${time}`));
    return end.diff(start, 'days') < MINIMUM_DURATION_DAYS;
};

const classDurationValidator = (
    startDate,
    startTime,
    endTime,
    durationAcknowledged,
    originalClassroomData
) => val => {
    const isRequiredValidationError = requiredField(val);
    if (!isRequiredValidationError) {
        const tooShort = isClassDurationLessThanRecommended(startDate, startTime, val, endTime);
        const startHasChanged = !isSameDay(
            moment.unix(originalClassroomData?.startsOn || 0),
            moment(startDate)
        );
        const endHasChanged = !isSameDay(
            moment.unix(originalClassroomData?.endsOn || 0),
            moment(val)
        );
        const inputsHaveChanged = startHasChanged || endHasChanged;
        if (tooShort && !durationAcknowledged && inputsHaveChanged) {
            return [{ code: validationErrorCodeMap.length, values: [val] }];
        }
        return false;
    }
    return isRequiredValidationError;
};

const validateJamEvent = jamEventValidator(requiredField, validationErrorCodeMap);

export const validateData = (
    data,
    userInfo = {},
    originalJamTrainings = {},
    originalClassroomData = {}
) => {
    const validators = {
        ...fieldValidators,
        endDate: classDurationValidator(
            data.startDate,
            data.startTime,
            data.endTime,
            data.shortClassDurationAcknowledged,
            originalClassroomData
        ),
    };
    // loop through fields to check if data is valid
    let dataValidity = fieldsToValidate.reduce(
        (acc, field) => ({
            ...acc,
            [field]: validators[field](data[field], {
                locationType: data.locationType,
                userInfo,
            }),
        }),
        {}
    );
    if (data.jamData?.challengeId) {
        dataValidity = {
            ...dataValidity,
            ...validateJamEvent(data, originalJamTrainings),
        };
    }
    return { dataValidity, allValid: allChecksPass(...Object.values(dataValidity)) };
};

// extra validator that makes sure all checks are not true
export const allChecksPass = (...values) => values.every(val => !val);

// given a form property that has multiple inputs (such as the list of instructors)
// this will find all the errors associated with the specific value to be able
// to display the errors alongside the field
export const aggregatePropertyErrors = (value, errors) => {
    if (!errors) return false;
    const aggregate = errors.filter(({ values }) => values.includes(value));
    return !aggregate.length ? false : aggregate;
};

export const getFirstErrorSectionId = fieldsInvalid => {
    if (allChecksPass(...Object.values(fieldsInvalid))) return;
    const firstErrorField = formFields.find(field => fieldsInvalid[field]);

    switch (firstErrorField) {
        case 'courseId':
        case 'courseVersionId':
        case 'langLocale':
            return 'course-information';
        case 'instructors':
            return 'class-provider';
        case 'classCapacity':
            return 'class-access';
        case 'timezone':
        case 'startDate':
        case 'endDate':
        case 'startTime':
        case 'endTime':
            return 'class-time';
        case 'virtualUrl':
        case 'addressLine1':
        case 'addressLine2':
        case 'city':
        case 'state':
        case 'postalCode':
        case 'country':
            return 'class-location';
        default:
            break;
    }
};

export const getDisabledCapacityInputHint = (ev, formatFn, templates = {}) => {
    if (!ev) {
        return '';
    }
    const mergedTemplates = {
        unplannedMessage: formMessages.messages.capacityDisabledUnplannedHint,
        plannedMessage: formMessages.messages.capacityDisabledPlannedHint,
        ...templates,
    };
    if (ev.type !== 'PLANNED') {
        return formatFn(mergedTemplates.unplannedMessage);
    }
    const endTime = moment.tz(moment.unix(ev.endTime), moment.tz.guess(true));
    const args = {
        date: endTime.format('MMMM D, YYYY'),
        time: endTime.format('LT z'),
    };
    return formatFn(mergedTemplates.plannedMessage, args);
};

export const waitForNode = id => {
    return new Promise(resolve => {
        if (document.getElementById(id)) {
            return resolve(document.getElementById(id));
        }
        const observer = new MutationObserver(mutations => {
            if (document.getElementById(id)) {
                resolve(document.getElementById(id));
                observer.disconnect();
            }
        });
        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    });
};

export const createStudentListEditState = (
    classroomId,
    providersMapInputValue,
    enableRosterModalOnLoad
) => {
    return {
        scrollToSection: 'class-access',
        returnToPath: paths.classDetailPage(classroomId),
        returnToState: {
            openStudentListModal: enableRosterModalOnLoad,
            providersMap: providersMapInputValue,
            classroomId,
        },
    };
};

export const getStudentListEditState = (classroomId, location) => {
    if (_.eq(classroomId, location.state?.returnToState?.classroomId)) {
        return location.state.returnToState;
    }
};

export const getInitialConfigFromStudentListEditState = (classroomId, location) => {
    if (_.eq(classroomId, location.state?.classroomId)) {
        return {
            openStudentListModal: location.state.openStudentListModal,
            providersMap: location.state.providersMap,
        };
    }
};

export * from './jamValidation';
