import React, { useContext, useState, Fragment, useEffect } from 'react';
import { Select, Button, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { LabToggle } from 'components';
import messages from './IngressTableHeader.messages';
import tableMessages from '../ingressTable/IngressTable.messages';
import endLabsModalMessages from '../endLabsModal/EndLabsModal.messages';
import detailsMessages from 'containers/classPages/ClassDetailPage/ClassDetailPage.messages';
import { ClassroomContext } from 'data/ClassroomContext';
import { nowInUnixTimeInSec } from 'utils/timestamp-utils';
import { useUserInfo } from 'utils/userInfo';
import {
    getIngressUrl,
    sendClientLog,
    getTrainingConfiguration,
    putTrainingConfiguration,
} from 'modules/api';
import {
    buildRegionGetFailureItem,
    buildRegionPutFailureItem,
    buildResetBannerItem,
    hiddenAlertBannerItem,
    isConsoleReady,
} from '../ingressTable/IngressTable.utils';
import { copyToClipboard, openTab, useStorage } from 'utils';
import { useFlags } from 'utils/flags';

const {
    refresh,
    preloadLabel,
    preloadSelectionLabel,
    optionSelectedLabel,
    copyIngressToClipboard,
} = messages;
const { ingressControl, tableHeaderRegion } = tableMessages;
const { urlCopied } = detailsMessages;
const { modalFooterEndLabLabel, modalFooterEndLabPluralLabel } = endLabsModalMessages;

const sendAppClickEvent = async ({ metricName, ...attributes }) => {
    try {
        await sendClientLog({
            type: 'AppClick',
            metrics: { [metricName]: [1, 'Count'] },
            attributes,
        });
    } catch (_) {
        console.log('failed analytics call');
    }
};

const IngressTableHeader = ({
    isSending,
    isSendingSet,
    performBatchProvisioning,
    performEndLab,
    trainingLoading,
    trainingRefetch,
    selectedItems,
    errorSet,
    tableItems = [],
    setRegionBannerItem,
    globals = window,
}) => {
    const { formatMessage } = useIntl();
    const flags = useFlags();
    const { classroomId, labNumber } = useParams();
    const {
        classData: { content, classroom },
    } = useContext(ClassroomContext);

    const { supportedRegions, contentId: labArn } = (!!content && content[labNumber - 1]) || {};
    const defaultLabRegion = { value: supportedRegions?.length ? supportedRegions[0] : null };
    const [targetRegion, targetRegionSet] = useState(defaultLabRegion);
    const [consoleUrlCopied, consoleUrlCopiedSet] = useState(false);
    const user = useUserInfo();
    const [ingressUrls, ingressUrlsSet] = useStorage(
        `ingressUrls::${classroomId}`,
        {},
        globals.sessionStorage
    );

    const persistRegionSelection = async region => {
        try {
            await putTrainingConfiguration({
                context: classroom.classroomArn,
                contentArn: labArn,
                contentType: 'SPL',
                configuration: {
                    targetRegions: [region],
                },
            });
        } catch (error) {
            setRegionBannerItem(
                buildRegionPutFailureItem(formatMessage, setRegionBannerItem, labNumber)
            );
        }
    };

    const autoPopulateRegion = async () => {
        setRegionBannerItem(hiddenAlertBannerItem);

        let fallbackRegion = targetRegion?.value;
        let notifyOnFallback = false;
        // Use the default region as fallback if the target region is not supported
        if (!supportedRegions?.includes(targetRegion?.value)) {
            fallbackRegion = defaultLabRegion.value;

            // Only notify the user if there was actually a previous selection
            if (targetRegion?.value) {
                notifyOnFallback = true;
            }
        }

        let region = fallbackRegion;
        let shouldPersistFallbackRegion = false;
        let usedFallbackRegion = true;
        if (flags.persistedRegionSelection) {
            try {
                const trainingConfiguration = await getTrainingConfiguration({
                    context: classroom.classroomArn,
                    contentArn: labArn,
                });

                if (!trainingConfiguration?.configuration?.targetRegions?.length) {
                    // We have confirmed there is no stored region, so we should persist whatever we pick for the user
                    shouldPersistFallbackRegion = true;
                } else {
                    // Use the stored region
                    setRegionBannerItem(hiddenAlertBannerItem);
                    region = trainingConfiguration.configuration.targetRegions[0];
                    usedFallbackRegion = false;
                }
            } catch (error) {
                // We are not able to confirm a stored region.
                // We will use the fallback, but we should only set one banner item in this scenario.
                setRegionBannerItem(
                    buildRegionGetFailureItem(formatMessage, setRegionBannerItem, labNumber)
                );
                notifyOnFallback = false;
            }

            if (shouldPersistFallbackRegion) {
                await persistRegionSelection(fallbackRegion);
            }
        }

        if (usedFallbackRegion && notifyOnFallback) {
            // We set an info banner that we used the fallback region only when no other error has occurred.
            setRegionBannerItem(
                buildResetBannerItem(formatMessage, setRegionBannerItem, targetRegion, labNumber)
            );
        }

        // Wait til the end to set the region
        targetRegionSet({ value: region });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => autoPopulateRegion(), [labNumber]);

    const preloadOnClick = () => {
        if (targetRegion?.value) {
            performBatchProvisioning([targetRegion.value]);
        } else {
            performBatchProvisioning();
        }
        setRegionBannerItem(hiddenAlertBannerItem);
    };

    const queryIngressUrl = async (labId, studentUserKey) => {
        if (isSending) return;
        isSendingSet(true);
        try {
            const payload = await getIngressUrl({
                classroomId,
                instructorUserKey: user.userKey,
                studentUserKey,
                labId,
            });

            // not awaiting this so it can happen in the background
            sendAppClickEvent({
                metricName: 'instructorClick',
                instructorUserKey: user.userKey,
                studentUserKey,
                labId,
            });
            return payload;
        } catch (error) {
            trainingRefetch();
            errorSet(true);
        } finally {
            isSendingSet(false);
        }
    };

    const getIngressUrlWithCallback = callback => async (labId, userKey) => {
        if (!labId || !userKey) {
            errorSet(true);
            isSendingSet(false);
            return;
        }
        const key = `${userKey}::${labId}`;
        const fromStorage = ingressUrls[key];
        const now = moment();
        if (fromStorage && fromStorage.urlExpiresAt > now.unix()) {
            callback(fromStorage);
            isSendingSet(false);
            return;
        }
        const consoleIngress = await queryIngressUrl(labId, userKey);
        if (consoleIngress && consoleIngress.consoleUrl) {
            ingressUrlsSet(urls => ({
                ...urls,
                [key]: {
                    ...consoleIngress,
                    urlExpiresAt: now.add(14, 'minutes').unix(),
                },
            }));
            callback(consoleIngress);
            isSendingSet(false);
        }
    };

    const copyIngressUrlToClipboard = getIngressUrlWithCallback(({ consoleUrl }) => {
        copyToClipboard({
            globals: window,
            value: consoleUrl,
        });
        consoleUrlCopiedSet(true);
        setTimeout(() => consoleUrlCopiedSet(false), 3000);
    });

    const classroomHasStarted = classroom.startsOn < nowInUnixTimeInSec();
    const rosterHasStudents = !!(classroom.studentRoster && classroom.studentRoster.length);
    const ingressConsole = getIngressUrlWithCallback(({ consoleUrl }) => openTab(consoleUrl));
    const ingressIsDisabled = () => {
        if (!Array.isArray(selectedItems) || selectedItems.length !== 1) {
            return true;
        }
        const liveItem = tableItems.find(
            item =>
                item?.arn === selectedItems[0]?.arn && item?.userKey === selectedItems[0]?.userKey
        );
        if (!isConsoleReady(liveItem?.metaData?.labStatus)) {
            return true;
        }
        return false;
    };

    const labButtonsDisabled =
        !classroomHasStarted ||
        (!flags?.pooledLabs && !rosterHasStudents) ||
        (flags?.endLabs && !selectedItems?.length);

    return (
        <Fragment>
            <Header
                variant="h2"
                actions={
                    <SpaceBetween size="s" direction="horizontal">
                        <Button
                            data-testid="ingress-table-header__ingress-btn"
                            onClick={() =>
                                ingressConsole(
                                    selectedItems[0].metaData?.labId,
                                    selectedItems[0].userKey
                                )
                            }
                            disabled={ingressIsDisabled()}
                            loading={isSending}
                        >
                            {formatMessage(ingressControl)}
                        </Button>
                        <Button
                            data-testid="ingress-table-header__copy-console-btn"
                            onClick={() => {
                                copyIngressUrlToClipboard(
                                    selectedItems[0].metaData?.labId,
                                    selectedItems[0].userKey
                                );
                            }}
                            disabled={ingressIsDisabled()}
                            loading={isSending}
                            iconName={consoleUrlCopied ? 'status-positive' : null}
                        >
                            {consoleUrlCopied
                                ? formatMessage(urlCopied)
                                : formatMessage(copyIngressToClipboard)}
                        </Button>
                        <Select
                            data-testid="ingress-select-preload"
                            selectedOption={{
                                value:
                                    formatMessage(tableHeaderRegion) + ':   ' + targetRegion.value,
                            }}
                            onChange={async ({ detail }) => {
                                setRegionBannerItem(hiddenAlertBannerItem);
                                targetRegionSet(detail.selectedOption);
                                if (flags.persistedRegionSelection) {
                                    await persistRegionSelection(detail.selectedOption.value);
                                }
                            }}
                            options={(supportedRegions ?? []).map(region => ({ value: region }))}
                            filteringType="auto"
                            selectedAriaLabel={formatMessage(optionSelectedLabel)}
                        />
                        <Button
                            data-testid="ingress-btn-preload"
                            ariaLabel={formatMessage(preloadLabel)}
                            loading={isSending}
                            disabled={labButtonsDisabled}
                            onClick={preloadOnClick}
                        >
                            {formatMessage(flags?.endLabs ? preloadSelectionLabel : preloadLabel)}
                        </Button>
                        {flags?.endLabs ? ( // https://sim.amazon.com/issues/BKR-5986
                            <Button
                                data-testid="ingress-btn-end"
                                onClick={performEndLab}
                                loading={isSending}
                                disabled={labButtonsDisabled}
                            >
                                {' '}
                                {formatMessage(
                                    selectedItems?.length === 1
                                        ? modalFooterEndLabLabel
                                        : modalFooterEndLabPluralLabel
                                )}{' '}
                            </Button>
                        ) : null}
                        <Button
                            data-testid="ingress-btn-refresh"
                            iconName="refresh"
                            iconAlt={formatMessage(refresh)}
                            ariaLabel={formatMessage(refresh)}
                            disabled={trainingLoading}
                            loading={trainingLoading}
                            onClick={() => {
                                !trainingLoading && trainingRefetch();
                            }}
                        ></Button>
                    </SpaceBetween>
                }
            >
                <LabToggle classroomId={classroomId} labArn={labArn} />
            </Header>
        </Fragment>
    );
};

export default IngressTableHeader;
