import React, { useMemo, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Button,
    ButtonDropdown,
    Container,
    Flashbar,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
} from '@amzn/awsui-components-react-v3/polaris';
import { useGetClassAttendance, useMarkClassAttendance } from 'data/useAttendanceManagement';
import { getEventHandlers, getInitialState, reducer } from './classAttendanceListStateManager';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    EmptyState,
    getColumnDefinitions,
    getColumnsToDisplay,
    getErrorSection,
    getPaginationLabels,
} from './ClassAttendanceListPreferences';
import _ from 'lodash';
import { submitAttendance } from './classAttendanceList.utils';
import { getStringBundle } from './ClassAttendanceList.messages';

/**
 * Component to display attendance information from AttendanceManagementService (AMS).
 * Attendance information consists of a list of students and their attendance status for each session.
 * Class attendance is calculated by AMS based on session attendance data.
 * ClassAttendanceList enables authorized users (i.e. instructors) to mark attendance per session for
 * every student.
 * Note that this module does not use Classroom's roster information and retrieves roster directly from AMS.
 */
const ClassAttendanceList = ({ classroom, learningActivity }) => {
    const intl = useIntl();
    const stringBundle = useMemo(() => getStringBundle(intl.formatMessage), [intl]);

    const { data: attendanceRecordList, error, isLoading, refetch } = useGetClassAttendance(
        learningActivity?.pk
    );
    const [attendanceListState, attendanceListStateDispatch] = useReducer(
        reducer,
        { learningActivity },
        getInitialState
    );
    const [notifications, setNotifications] = useState([]);
    const markAttendanceFn = useMarkClassAttendance();

    const eventHandlers = useMemo(() => {
        return getEventHandlers(attendanceListStateDispatch, setNotifications);
    }, [attendanceListStateDispatch, setNotifications]);

    useMemo(() => eventHandlers.onAttendanceRecordLoaded(attendanceRecordList, stringBundle), [
        attendanceRecordList,
        eventHandlers,
        stringBundle,
    ]);

    const columnDefinitions = useMemo(() => {
        return getColumnDefinitions(
            learningActivity,
            attendanceListState,
            eventHandlers,
            stringBundle
        );
    }, [learningActivity, eventHandlers, attendanceListState, stringBundle]);

    const columnDisplay = useMemo(() => {
        return getColumnsToDisplay(learningActivity, attendanceListState.editMode);
    }, [learningActivity, attendanceListState.editMode]);

    const { items, actions, collectionProps, filterProps, paginationProps } = useCollection(
        attendanceListState.attendanceRecordList,
        {
            filtering: {
                empty: <EmptyState title={stringBundle.filteringNoResult()} />,
                noMatch: (
                    <EmptyState
                        title={stringBundle.filteringNoMatch()}
                        action={
                            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
                        }
                    />
                ),
                fields: ['email', 'lastName', 'statusLabels'],
            },
            sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
            selection: {},
            pagination: { pageSize: 25 },
        }
    );

    const actionOptions = buildActionOptions(learningActivity, stringBundle);

    const listProps = {
        attendanceListState,
        classroom,
        collectionProps,
        columnDefinitions,
        columnDisplay,
        eventHandlers,
        filterProps,
        isLoading,
        items,
        markAttendanceFn,
        notifications,
        paginationProps,
        refetch,
        stringBundle,
    };

    if (learningActivity) {
        if (error) {
            return getErrorSection(error, stringBundle);
        } else if (attendanceListState.editMode) {
            return <ClassAttendanceListEditMode {...listProps} actionOptions={actionOptions} />;
        } else {
            return <ClassAttendanceListViewMode {...listProps} />;
        }
    } else {
        return null;
    }
};

const ClassAttendanceListViewMode = ({
    attendanceListState,
    classroom,
    collectionProps,
    columnDefinitions,
    columnDisplay,
    eventHandlers,
    filterProps,
    isLoading,
    items,
    notifications,
    paginationProps,
    refetch,
    stringBundle,
}) => {
    return (
        <Container>
            <Table
                {...collectionProps}
                header={
                    <SpaceBetween size="m">
                        <Header
                            variant="h1"
                            counter={
                                attendanceListState.learnersWithSessionAttendanceChanges.size > 0
                                    ? `(${stringBundle.counterText(
                                          attendanceListState.learnersWithSessionAttendanceChanges
                                              .size
                                      )})`
                                    : ''
                            }
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        variant="normal"
                                        onClick={() => {
                                            refetch();
                                        }}
                                        disabled={isLoading}
                                        iconName="refresh"
                                    />
                                    <Button
                                        variant="primary"
                                        onClick={() => eventHandlers.onListModeChangeToEdit()}
                                        disabled={isLoading}
                                    >
                                        Update Attendance
                                    </Button>
                                </SpaceBetween>
                            }
                        >
                            {stringBundle.titleViewMode(
                                classroom?.course?.title,
                                classroom?.classroom?.classroomId
                            )}
                        </Header>
                        <Flashbar items={notifications} />
                    </SpaceBetween>
                }
                columnDefinitions={columnDefinitions}
                columnDisplay={columnDisplay}
                items={items}
                loading={isLoading || attendanceListState.attendanceUpdateInProgress}
                loadingText={
                    isLoading
                        ? stringBundle.loadingText()
                        : stringBundle.markAttendanceInProgressText()
                }
                stripedRows={true}
                filter={<TextFilter {...filterProps} filteringAriaLabel="Filter instances" />}
                pagination={
                    <Pagination
                        {...paginationProps}
                        ariaLabels={getPaginationLabels(stringBundle)}
                    />
                }
            />
        </Container>
    );
};

const ClassAttendanceListEditMode = ({
    actionOptions,
    attendanceListState,
    collectionProps,
    columnDefinitions,
    columnDisplay,
    eventHandlers,
    filterProps,
    isLoading,
    items,
    markAttendanceFn,
    notifications,
    paginationProps,
    refetch,
    stringBundle,
}) => {
    return (
        <Container>
            <Table
                {...collectionProps}
                header={
                    <SpaceBetween size="m">
                        <Header
                            variant="h1"
                            description={stringBundle.editModeDescription()}
                            counter={
                                attendanceListState.learnersWithSessionAttendanceChanges.size > 0
                                    ? `(${stringBundle.counterText(
                                          attendanceListState.learnersWithSessionAttendanceChanges
                                              .size
                                      )})`
                                    : ''
                            }
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <ButtonDropdown
                                        items={actionOptions}
                                        onItemClick={event =>
                                            eventHandlers.onBulkActionClicked(
                                                event,
                                                parseAttendanceStatusWithDeliverySession(
                                                    event.detail.id
                                                ),
                                                collectionProps.selectedItems,
                                                stringBundle
                                            )
                                        }
                                        disabled={attendanceListState.attendanceUpdateInProgress}
                                    >
                                        Actions
                                    </ButtonDropdown>
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            submitAttendance({
                                                markAttendanceFn,
                                                attendanceState: attendanceListState,
                                                eventHandlers,
                                                attendanceRefetcher: refetch,
                                                stringBundle,
                                            })
                                        }
                                        disabled={
                                            _.isEmpty(
                                                attendanceListState.learnersWithSessionAttendanceChanges
                                            ) || attendanceListState.attendanceUpdateInProgress
                                        }
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        onClick={() => eventHandlers.onListModeChangeToView()}
                                        disabled={attendanceListState.attendanceUpdateInProgress}
                                    >
                                        Cancel
                                    </Button>
                                </SpaceBetween>
                            }
                        >
                            {stringBundle.titleEditMode()}
                        </Header>
                        <Flashbar items={notifications} />
                    </SpaceBetween>
                }
                columnDefinitions={columnDefinitions}
                columnDisplay={columnDisplay}
                items={items}
                loading={isLoading || attendanceListState.attendanceUpdateInProgress}
                loadingText={
                    isLoading
                        ? stringBundle.loadingText()
                        : stringBundle.markAttendanceInProgressText()
                }
                selectionType="multi"
                stripedRows={true}
                filter={<TextFilter {...filterProps} filteringAriaLabel="Filter instances" />}
                pagination={
                    <Pagination
                        {...paginationProps}
                        ariaLabels={getPaginationLabels(stringBundle)}
                    />
                }
            />
        </Container>
    );
};

const buildActionOptions = (learnerActivity, stringBundle) => {
    return _.map(learnerActivity.delivery_sessions, (deliverySession, index) => {
        return {
            id: `session${index + 1}`,
            text: stringBundle.sessionNth(index + 1),
            items: [
                {
                    text: stringBundle.statusAttended(),
                    id: buildAttendanceStatusWithDeliverySession(deliverySession.id, 'ATTENDED'),
                },
                {
                    text: stringBundle.statusPartiallyAttended(),
                    id: buildAttendanceStatusWithDeliverySession(
                        deliverySession.id,
                        'PARTIALLY_ATTENDED'
                    ),
                },
                {
                    text: stringBundle.statusNoShow(),
                    id: buildAttendanceStatusWithDeliverySession(deliverySession.id, 'NO_SHOW'),
                },
            ],
        };
    });
};

const buildAttendanceStatusWithDeliverySession = (deliverySessionId, attendanceStatus) => {
    return `${deliverySessionId}::${attendanceStatus}`;
};

const parseAttendanceStatusWithDeliverySession = attendanceStatusWithDeliverySession => {
    const [deliverySessionId, attendanceStatus] = _.split(
        attendanceStatusWithDeliverySession,
        '::',
        2
    );
    return {
        deliverySessionId,
        attendanceStatus,
    };
};

export default ClassAttendanceList;
