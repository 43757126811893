import { gql, useQuery } from '@apollo/client';
import { usePrepareClient } from 'modules/supergraph/client';
import { useFlags } from '../utils';

const GET_LEARNING_ACTIVITY = gql`
    query QueryLearningActivity($learningActivityId: ID!) {
        learningActivity(pk: $learningActivityId) {
            pk
            activity_status
            class_size
            registrationCounter
            attendedCounter
            lms_type
            customers {
                customer_aws_account_id
            }
            delivery_sessions {
                id
                start_timestamp
                end_timestamp
            }
            instructors {
                role
                name
                pk
                type
                email
            }
        }
    }
`;
export const useGetLearningActivity = learningActivityId => {
    const ready = usePrepareClient();
    const flags = useFlags();
    const { loading, error, data, refetch } = useQuery(GET_LEARNING_ACTIVITY, {
        variables: {
            learningActivityId,
        },
        skip: !flags.attendanceMarking || !ready || !learningActivityId,
    });

    return {
        data: data?.learningActivity,
        isLoading: loading,
        error,
        refetch,
    };
};
