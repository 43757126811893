import { useIntl } from 'react-intl';
import * as JAM_STATUSES from 'utils/jam-status-definitions';
import { Box, Icon } from '@amzn/awsui-components-react-v3';
import { getStatusTranslationMessageBase } from 'components/jamStatusWidget/JamStatusWidget.utils';

const getStatusBadgeColorAndIcon = status => {
    switch (status) {
        case JAM_STATUSES.JAM_EVENT_ONGOING:
        case JAM_STATUSES.JAM_EVENT_NOT_STARTED:
            return {
                color: 'text-status-success',
                icon: 'status-positive',
            };
        case JAM_STATUSES.JAM_EVENT_ENDED:
        case JAM_STATUSES.JAM_EVENT_CANCELLED:
            return {
                color: 'text-status-inactive',
                icon: 'status-stopped',
            };
        case JAM_STATUSES.JAM_EVENT_ERROR:
            return {
                color: 'text-status-error',
                icon: 'status-negative',
            };
        default:
            return {
                color: 'text-status-inactive',
                icon: null,
            };
    }
};

/**
 * A widget to display the status of a Jam training.
 * @param status - the Jam's status
 */
const JamStatusCardLabel = ({ status }) => {
    const { formatMessage } = useIntl();
    const getStatusTranslationMessage = getStatusTranslationMessageBase(formatMessage);
    if (!status) return null;
    const { color, icon } = getStatusBadgeColorAndIcon(status);
    return (
        <Box data-testid="jam-status-card-label" color={color}>
            {' '}
            <Icon name={icon} /> {getStatusTranslationMessage(status)}
        </Box>
    );
};

export default JamStatusCardLabel;
