import { Breadcrumbs, GilmoreHealthBanner, PageWrapper } from 'components';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { paths } from 'utils';
import React from 'react';
import { classDetailPageBreadcrumb, classesPageBreadcrumb } from 'utils/breadcrumbs';
import { useIntl } from 'react-intl';
import { useClassroomData } from 'data/useClassroomData';
import ClassAttendanceList from 'components/classAttendance/ClassAttendanceList';
import { useGetLearningActivity } from 'data/useLearningActivity';
import { Container, Spinner } from '@amzn/awsui-components-react-v3';
import messages from 'containers/classPages/ClassDetailPage/ClassDetailPage.messages';
import { useGetAttendanceListingEnabled } from 'data/useAttendanceManagement';

const ClassAttendancePage = () => {
    const { classroomId: encodedClassroomId } = useParams();
    const classroomId = encodedClassroomId ? decodeURIComponent(encodedClassroomId) : undefined;
    const { formatMessage } = useIntl();
    const { classData } = useClassroomData(classroomId, {
        withConsistencyRetry: true,
        withInstructors: false,
    });
    const { data: learnerActivity } = useGetLearningActivity(
        classData?.classroom?.learningActivityID
    );

    const enableAttendanceMarking = useGetAttendanceListingEnabled(learnerActivity);

    return (
        <PageWrapper contentLayoutType="wide">
            <Helmet>
                <title>Class Attendance</title>
            </Helmet>
            <GilmoreHealthBanner />
            <Breadcrumbs
                items={[
                    classesPageBreadcrumb(formatMessage),
                    classDetailPageBreadcrumb(formatMessage, classroomId),
                    {
                        text: 'Attendance',
                        href: paths.classEditPage(classroomId),
                    },
                ]}
            />

            {enableAttendanceMarking ? (
                <ClassAttendanceList classroom={classData} learningActivity={learnerActivity} />
            ) : (
                <Container>
                    {' '}
                    <Spinner /> {formatMessage(messages.classLoading)}
                </Container>
            )}
        </PageWrapper>
    );
};

export default ClassAttendancePage;
