import React from 'react';

export const FlagContext = React.createContext();

const toBooleanValue = val => /^true$/i.test(val);
export const FlagContextProvider = ({ children }) => (
    <FlagContext.Provider
        value={{
            studentRoster: toBooleanValue(process.env.studentRoster),
            studentLandingPage: toBooleanValue(process.env.studentLandingPage), // https://sim.amazon.com/issues/BKR-6057
            cancelClassroom: toBooleanValue(process.env.cancelClassroom), // https://sim.amazon.com/issues/BKR-4805
            // TODO: (https://sim.amazon.com/issues/BKR-4926) remove class listing phase 2 flag after release
            classListingV2: toBooleanValue(process.env.classListingV2),
            enableClassListingWithMock: toBooleanValue(process.env.enableClassListingWithMock),
            scheduledEvents: toBooleanValue(process.env.scheduledEvents),
            multiGilmoreId: toBooleanValue(process.env.multiGilmoreId),
            endLabs: toBooleanValue(process.env.endLabs), // https://sim.amazon.com/issues/BKR-5986
            // https://issues.amazon.com/issues/BKR-6322
            persistedRegionSelection: toBooleanValue(process.env.persistedRegionSelection),
            pooledLabs: toBooleanValue(process.env.pooledLabs),
            aurousIntegration: toBooleanValue(process.env.aurousIntegration),
            ccsIntegration: toBooleanValue(process.env.ccsIntegration), // https://issues.amazon.com/issues/BKR-6821,
            enableCcsIntegrationWithMock: toBooleanValue(process.env.enableCcsIntegrationWithMock), // https://issues.amazon.com/issues/BKR-6821
            attendanceMarking: toBooleanValue(process.env.attendanceMarking),
            resourceConsolidation: toBooleanValue(process.env.resourceConsolidation), // https://issues.amazon.com/issues/BKR-6995
            requiresReset: toBooleanValue(process.env.requiresReset), // https://issues.amazon.com/issues/BKR-6995
            grimsbyIntegration: toBooleanValue(process.env.grimsbyIntegration), // https://sim.amazon.com/issues/BKR-7186
            enableMultiGilmoreIdPhase2: toBooleanValue(process.env.enableMultiGilmoreIdPhase2), // https://sim.amazon.com/issues/BKR-7159
        }}
    >
        {children}
    </FlagContext.Provider>
);

export const useFlags = () => React.useContext(FlagContext);
