/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateTrainingPermissions = /* GraphQL */ `
  subscription OnUpdateTrainingPermissions {
    onUpdateTrainingPermissions {
      classroomId
      activeTrainings
      status
      __typename
    }
  }
`;
