import { useEffect, useRef, useState } from 'react';
import { acceptInvitations } from 'graphql/mutations';
import { executeRequest } from 'modules/api';
import { useErrorHandler } from 'react-error-boundary';

const acceptAllInvitations = async (stateSetter, errorSetter, collection = []) => {
    let acceptedInvitations = [];
    try {
        acceptedInvitations = await executeRequest({ operation: acceptInvitations })
            .then(response => response?.acceptInvitations?.acceptedInvitations || [])
            .catch(errorSetter);
    } catch (err) {
        errorSetter(err);
    }
    if (acceptedInvitations.length) {
        acceptAllInvitations(stateSetter, errorSetter, [...collection, ...acceptedInvitations]);
    }
    stateSetter(collection);
};

export const useAcceptInvitations = () => {
    const [acceptedInvitations, acceptedInvitationsSet] = useState(null);
    const errorSet = useRef(useErrorHandler());

    useEffect(() => {
        acceptAllInvitations(acceptedInvitationsSet, errorSet.current);
    }, []);

    return acceptedInvitations;
};
