import { ButtonDropdown } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useParams, useHistory } from 'react-router-dom';

import { paths } from 'utils/paths';
import { getStudentURL, openTab } from 'utils/helpers';

import messages from './NavigationDropdown.messages';
import { useClassroomData } from 'data/useClassroomData';
const { dayOf, classDetails, manageLabs, studentView, externalLabel } = messages;

export const findCurrentPage = (path, classroomId, labNumber) => {
    switch (path) {
        case paths.classPage(classroomId):
            return dayOf;
        case paths.classDetailPage(classroomId):
            return classDetails;
        case paths.labPage(classroomId, labNumber):
            return manageLabs;
        default:
            return null;
    }
};

const NavigationDropdown = () => {
    const { formatMessage } = useIntl();
    const { classroomId: encodedClassroomId, labNumber } = useParams();
    const classroomId = encodedClassroomId ? decodeURIComponent(encodedClassroomId) : undefined;
    const { pathname } = useLocation();
    const { isJamOnlyClassroom } = useClassroomData(classroomId);
    const history = useHistory();

    const onClick = e => {
        e.preventDefault();
        const { external, href } = e.detail;
        if (external) {
            openTab(href);
        } else {
            history.push(href);
        }
    };

    const currentPage = findCurrentPage(pathname, classroomId, labNumber);

    if (!currentPage) return null;

    const dropdownItems = [
        {
            id: 'day-of',
            href: paths.classPage(classroomId),
            text: formatMessage(dayOf),
            disabled: currentPage === dayOf,
        },
        {
            id: 'details',
            href: paths.classDetailPage(classroomId),
            text: formatMessage(classDetails),
            disabled: currentPage === classDetails,
        },
        {
            id: 'labs',
            href: paths.labPage(classroomId, labNumber || '1'),
            text: formatMessage(manageLabs),
            disabled: isJamOnlyClassroom || currentPage === manageLabs,
        },
        {
            id: 'student-view',
            href: getStudentURL(classroomId),
            text: formatMessage(studentView),
            external: true,
            externalIconAriaLabel: formatMessage(externalLabel),
        },
    ];

    return (
        <ButtonDropdown items={dropdownItems} onItemClick={onClick} className="navigation-dropdown">
            {formatMessage(currentPage)}
        </ButtonDropdown>
    );
};

export default NavigationDropdown;
