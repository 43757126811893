import Amplify, { Auth } from 'aws-amplify';
import environment from '../../.config/environment';

const {
    region,
    domains,
    userPoolId,
    clientId,
    identityPoolId,
    hostName: { instructorGql },
} = environment(window);

export const createUrlInterceptor = ({ issuer, login, logout }, verifyEmail = false) => url => {
    const SELF = '_self';
    const loginRequestUrl = `https://${issuer}/oauth2/authorize`;
    const logoutRequestUrl = `https://${issuer}/logout`;
    let windowProxy;

    if (login && url.startsWith(loginRequestUrl)) {
        const signInUrl =
            url.replace(loginRequestUrl, `https://${login}/login`) +
            `&require_email_verification=${verifyEmail}`;
        windowProxy = window.open(signInUrl, SELF);
    } else if (logout && url.startsWith(logoutRequestUrl)) {
        const signOutUrl = url.replace(logoutRequestUrl, `https://${logout}/logout`);
        windowProxy = window.open(signOutUrl, SELF);
    } else {
        windowProxy = window.open(url, SELF);
    }
    return windowProxy ? Promise.resolve(windowProxy) : Promise.reject();
};

const AMPLIFY_CONFIG = (verifyEmail = false) => ({
    Auth: {
        region,
        identityPoolId,
        userPoolId: userPoolId,
        userPoolWebClientId: clientId,
        oauth: {
            domain: domains.issuer,
            scope: ['openid'],
            redirectSignIn: `${window.location.origin}/callback`,
            redirectSignOut: `${window.location.origin}/providers`,
            responseType: 'code',
            codeChallengeMethod: 'S256',
            codeChallenge: encodeURI(btoa(Math.random())),
            urlOpener: createUrlInterceptor(domains, verifyEmail),
        },
    },
    API: {
        aws_appsync_graphqlEndpoint: instructorGql,
        aws_appsync_region: region,
        aws_appsync_authenticationType: 'OPENID_CONNECT',
        graphql_headers: async () => ({
            Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        }),
    },
});

export function configureAmplify(verifyEmail = false) {
    Amplify.configure(AMPLIFY_CONFIG(verifyEmail));
}
