import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';
import { FormField, DatePicker, FormSection, TimeInput } from '@amzn/awsui-components-react';
import FormFieldV3 from '@amzn/awsui-components-react-v3/polaris/form-field';

import { TimeZoneSelect } from 'components';
import { messages } from '../ClassForm.messages';
import {
    handleFormValueChange,
    allChecksPass,
    isClassDurationLessThanRecommended,
} from '../ClassForm.utils';
import { FieldErrors, ErrorMessage } from '../FieldErrors';
import {
    isInvalidDate,
    isInvalidTime,
    isInvalidDateDiff,
    isDateTimeInPast,
    dateTimeToUnix,
    unixToDateString,
    isEndDateEnabled,
    DATEPICKER_FORMAT,
    isSameDay,
} from 'utils/timestamp-utils';
import '../ClassForm.css';

const ClassTime = ({
    fieldsInvalid = {},
    timezone,
    setClassData,
    dateTimeData,
    setDateTimeData,
    setDateTimesAreValid,
    hasClassStarted,
    hasClassExpired,
    isNewClass,
    isJamOnly,
    startsOn,
    endsOn,
    triggerDurationWarningModal,
    durationWarningAcknowledged,
    isGrimsbyClass,
}) => {
    const { formatMessage, locale } = useIntl();

    const endDateInPast =
        !hasClassStarted && isDateTimeInPast(dateTimeData.endDate, dateTimeData.endTime, timezone);
    const endDateBeforeStartDate = isInvalidDate(dateTimeData.startDate, dateTimeData.endDate);
    // verify end date is within 5 weeks of start date
    const endDateTooFar =
        isNewClass && isInvalidDateDiff(dateTimeData.startDate, dateTimeData.endDate);
    const endDateExtensionTooFar =
        !isNewClass && isInvalidDateDiff(unixToDateString(endsOn, timezone), dateTimeData.endDate);

    const grimsbyEditsDisabled = !!isGrimsbyClass && !isNewClass;

    const endDateTimeReduced =
        hasClassStarted &&
        dateTimeToUnix(dateTimeData.endDate, dateTimeData.endTime, timezone) < endsOn;
    const endTimeBeforeStartTime = isInvalidTime(dateTimeData);

    const endDateIsValid = allChecksPass(
        endDateInPast,
        endDateBeforeStartDate,
        endDateTooFar,
        endDateExtensionTooFar,
        endDateTimeReduced,
        endTimeBeforeStartTime
    );

    const startDateHasChanged = !isSameDay(
        moment.unix(startsOn || 0),
        moment(dateTimeData.startDate)
    );
    const endDateHasChanged = !isSameDay(moment.unix(endsOn || 0), moment(dateTimeData.endDate));
    const inputsHaveChanged = startDateHasChanged || endDateHasChanged;

    useEffect(() => {
        setDateTimesAreValid(endDateIsValid);
    }, [endDateIsValid, setDateTimesAreValid]);

    const classDurationIsTooShort = () =>
        inputsHaveChanged &&
        !durationWarningAcknowledged &&
        isClassDurationLessThanRecommended(
            dateTimeData.startDate,
            dateTimeData.startTime,
            dateTimeData.endDate,
            dateTimeData.endTime
        );

    const isFirstRun = useRef(true);
    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (classDurationIsTooShort() && (isNewClass || inputsHaveChanged)) {
            triggerDurationWarningModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify({ ...dateTimeData, timezone })]);

    return (
        <FormSection id="class-time" header={formatMessage(messages.classTimeHeader)}>
            <div className="awsui-grid">
                <div className="awsui-row">
                    <div className="col-12">
                        <FormFieldV3 stretch label={formatMessage(messages.timeZoneLabel)}>
                            <TimeZoneSelect
                                invalid={!!fieldsInvalid.timezone || endDateInPast}
                                disabled={
                                    hasClassStarted || hasClassExpired || grimsbyEditsDisabled
                                }
                                selected={timezone}
                                onChange={e => {
                                    handleFormValueChange({
                                        value: e.detail.selectedOption.id,
                                        setData: setClassData,
                                        keyPath: 'timezone',
                                    });
                                }}
                            />
                            <FieldErrors fieldsInvalid={fieldsInvalid} property="timezone" />
                        </FormFieldV3>
                    </div>
                </div>
                {!isJamOnly && (
                    <>
                        <div className="awsui-row">
                            <div className="col-xxxs-12 col-xxs-6 col-xs-6 col-s-6">
                                <FormField stretch label={formatMessage(messages.startDateLabel)}>
                                    <DatePicker
                                        id="classForm__startDate"
                                        ariaRequired
                                        invalid={!!fieldsInvalid.startDate}
                                        disabled={
                                            hasClassStarted ||
                                            hasClassExpired ||
                                            grimsbyEditsDisabled
                                        }
                                        locale={locale}
                                        value={dateTimeData.startDate}
                                        onChange={e => {
                                            handleFormValueChange({
                                                value: e.detail.value,
                                                setData: setDateTimeData,
                                                keyPath: 'startDate',
                                            });
                                        }}
                                        placeholder="YYYY/MM/DD"
                                        todayLabel={formatMessage(messages.todayLabel)}
                                        nextMonthLabel={formatMessage(messages.nextMonthLabel)}
                                        previousMonthLabel={formatMessage(
                                            messages.previousMonthLabel
                                        )}
                                    ></DatePicker>
                                    <FieldErrors
                                        fieldsInvalid={fieldsInvalid}
                                        property="startDate"
                                    />
                                </FormField>
                            </div>
                            <div className="col-xxxs-12 col-xxs-6 col-xs-6 col-s-6">
                                <FormField
                                    stretch
                                    label={formatMessage(messages.endDateLabel)}
                                    description={formatMessage(messages.endDateDescription)}
                                >
                                    <DatePicker
                                        id="classForm__endDate"
                                        ariaRequired
                                        invalid={
                                            !!fieldsInvalid.endDate || classDurationIsTooShort()
                                        }
                                        disabled={hasClassExpired || grimsbyEditsDisabled}
                                        locale={locale}
                                        value={dateTimeData.endDate}
                                        onChange={e => {
                                            handleFormValueChange({
                                                value: e.detail.value,
                                                setData: setDateTimeData,
                                                keyPath: 'endDate',
                                            });
                                        }}
                                        placeholder="YYYY/MM/DD"
                                        todayLabel={formatMessage(messages.todayLabel)}
                                        nextMonthLabel={formatMessage(messages.nextMonthLabel)}
                                        previousMonthLabel={formatMessage(
                                            messages.previousMonthLabel
                                        )}
                                        isDateEnabled={date => {
                                            if (isNewClass) return true;
                                            return isEndDateEnabled(
                                                unixToDateString(endsOn, timezone),
                                                moment(date).format(DATEPICKER_FORMAT),
                                                hasClassStarted
                                            );
                                        }}
                                    ></DatePicker>
                                    <FieldErrors fieldsInvalid={fieldsInvalid} property="endDate" />
                                    {endDateInPast ? (
                                        <ErrorMessage>
                                            {formatMessage(messages.endDateEndDatePassed)}
                                        </ErrorMessage>
                                    ) : null}
                                    {endDateBeforeStartDate ? (
                                        <ErrorMessage>
                                            {formatMessage(messages.endDateEndBeforeStart)}
                                        </ErrorMessage>
                                    ) : null}
                                    {endDateTooFar ? (
                                        <ErrorMessage>
                                            {formatMessage(messages.endDateDurationExceeded)}
                                        </ErrorMessage>
                                    ) : null}
                                    {endDateExtensionTooFar ? (
                                        <ErrorMessage>
                                            {formatMessage(messages.endDateExtensionInvalid, {
                                                date: unixToDateString(endsOn, timezone, 5),
                                            })}
                                        </ErrorMessage>
                                    ) : null}
                                    {endDateTimeReduced ? (
                                        <ErrorMessage>
                                            {formatMessage(messages.endDateReductionInvalid)}
                                        </ErrorMessage>
                                    ) : null}
                                </FormField>
                            </div>
                        </div>
                        <div className="awsui-row">
                            <div className="col-xxxs-12 col-xxs-6 col-xs-6 col-s-6">
                                <FormField
                                    stretch
                                    id="FormField__startTime"
                                    controlId="classForm__startTime"
                                    label={formatMessage(messages.startTimeLabel)}
                                >
                                    <TimeInput
                                        format="hh:mm"
                                        placeholder="hh:mm"
                                        controlId="classForm__startTime"
                                        className="class-form__time-input"
                                        aria-labelledby="FormField__startTime"
                                        invalid={!!fieldsInvalid.startTime}
                                        disabled={
                                            hasClassStarted ||
                                            hasClassExpired ||
                                            grimsbyEditsDisabled
                                        }
                                        onChange={e => {
                                            handleFormValueChange({
                                                value: e.detail.value,
                                                setData: setDateTimeData,
                                                keyPath: 'startTime',
                                            });
                                        }}
                                        ariaRequired
                                        value={dateTimeData.startTime}
                                    />
                                    <FieldErrors
                                        fieldsInvalid={fieldsInvalid}
                                        property="startTime"
                                    />
                                </FormField>
                            </div>
                            <div className="col-xxxs-12 col-xxs-6 col-xs-6 col-s-6">
                                <FormField
                                    stretch
                                    id="FormField__endTime"
                                    controlId="classForm__endTime"
                                    label={formatMessage(messages.endTimeLabel)}
                                >
                                    <TimeInput
                                        format="hh:mm"
                                        placeholder="hh:mm"
                                        controlId="classForm__endTime"
                                        className="class-form__time-input"
                                        aria-labelledby="FormField__endTime"
                                        invalid={
                                            !!fieldsInvalid.endTime ||
                                            endTimeBeforeStartTime ||
                                            endDateInPast ||
                                            endDateTimeReduced
                                        }
                                        disabled={hasClassExpired || grimsbyEditsDisabled}
                                        onChange={e => {
                                            handleFormValueChange({
                                                value: e.detail.value,
                                                setData: setDateTimeData,
                                                keyPath: 'endTime',
                                            });
                                        }}
                                        ariaRequired
                                        value={dateTimeData.endTime}
                                    />
                                    <FieldErrors fieldsInvalid={fieldsInvalid} property="endTime" />
                                    {endTimeBeforeStartTime ? (
                                        <ErrorMessage>
                                            {formatMessage(messages.startEndTimeInvalid)}
                                        </ErrorMessage>
                                    ) : null}
                                </FormField>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </FormSection>
    );
};

export default ClassTime;
