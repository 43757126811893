import React from 'react';
import { useQuery, useQueries } from 'react-query';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';

import { listClassrooms, getCourseVersionDetails, listClassroomInstructors } from 'modules';
import { useProvider } from 'data/ProviderContext';
import { usePagination } from 'utils/usePagination';
import ClassTable from './ClassTable';
import { columnDefinitions } from './columnDefinitions';
import { addSupplementalClassroomData } from './utils';

const useQueryOptions = {
    refetchOnWindowFocus: false,
    staleTime: 5000 * 60, // 5 minutes before refetching all classes
};

const operation = 'listProviderClassrooms';

const today = moment()
    .startOf('day')
    .toISOString();

const ProviderClassesTable = ({ filter }) => {
    const { formatMessage } = useIntl();
    const providerArn = useProvider()?.arn;
    const { currentPage, addPage, goToPage, ...paginationProps } = usePagination();
    const { data, isLoading } = useQuery(
        [operation, providerArn, today, currentPage],
        () =>
            providerArn
                ? listClassrooms({ providerArn, operation, after: today, nextToken: currentPage })
                : null,
        useQueryOptions
    );
    const { classrooms = [], courseIds = [], nextToken } = data || {};

    const courseQueries = useQueries(
        courseIds.map(courseId => ({
            queryKey: ['getCourseVersion', courseId],
            queryFn: () => getCourseVersionDetails(courseId, providerArn),
            ...useQueryOptions,
        }))
    );

    const instructorsQueries = useQueries(
        classrooms.map(({ classroomId }) => ({
            queryKey: ['listClassroomInstructors', classroomId, providerArn],
            queryFn: () => listClassroomInstructors(classroomId, providerArn),
            ...useQueryOptions,
        }))
    );

    const supplementedClassroomData = addSupplementalClassroomData(
        classrooms,
        courseQueries.map(query => query.data),
        instructorsQueries.map(query => query.data)
    );

    React.useEffect(() => {
        if (nextToken) {
            addPage(nextToken);
        }
    }, [nextToken, addPage]);

    return (
        <ClassTable
            isLoading={isLoading}
            data={supplementedClassroomData}
            filter={filter}
            columnDefinitions={columnDefinitions(formatMessage, true)}
            onPageChange={goToPage}
            {...paginationProps}
        />
    );
};

export default ProviderClassesTable;
