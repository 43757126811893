import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
    startDateInputLabel: {
        id: 'classForm.jam.startDateInputLabel',
        defaultMessage: 'Start date',
    },
    startDateInputDescription: {
        id: 'classForm.jam.startDateInputDescription',
        defaultMessage: 'What day would you like your Jam to take place?',
    },
    startDateInputWarning: {
        id: 'classForm.jam.startDateInputWarning',
        defaultMessage: 'This date cannot be changed once the Jam has started.',
    },
    startTimeInputLabel: {
        id: 'classForm.jam.startTimeInputLabel',
        defaultMessage: 'Start time',
    },
    startTimeInputDescription: {
        id: 'classForm.jam.startTimeInputDescription',
        defaultMessage: 'What time should your Jam event begin?',
    },
    startTimeInputWarning: {
        id: 'classForm.jam.startTimeInputWarning',
        defaultMessage: 'Your Jam event will be available for {duration} hours.'
    },
    teamSizeInputLabel: {
        id: 'classForm.jam.teamSizeInputLabel',
        defaultMessage: 'Team size',
    },
    teamSizeInputDescription: {
        id: 'classForm.jam.teamSizeInputDescription',
        defaultMessage:
            'Enter how many participants per team. Jam team size must be between {lower} and {upper} participants.',
    },

    // header
    sectionHeaderDescription: {
        id: 'classForm.jam.sectionHeaderDescription',
        defaultMessage:
            'AWS Jam is an immersive experiential learning product that helps learners develop the skills to build on the AWS Cloud through simulated real-world use-cases.',
    },
    sectionHeaderBody: {
        id: 'classForm.jam.sectionHeaderBody',
        defaultMessage: 'AWS Jam',
    },
});
